import ApiService from "../services/ApiService";
import { END_POINTS } from "../utils/ApiEndpoints";

const BASE_API_URL = ApiService.getApiUrl();

export const getPONo = async () => {
  const URL = BASE_API_URL + END_POINTS.GET_PO_NO;
  return ApiService.callGetApi(URL);
};

export const getBillNo = async () => {
  const URL = BASE_API_URL + END_POINTS.GET_BILL_NO;
  return ApiService.callGetApi(URL);
};

export const CreatePO = (payload: any) => {
  const URL = BASE_API_URL + END_POINTS.CREATE_PO;
  return ApiService.callPostApi(URL, payload);
};
