import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCaretRight,
	faUser,
	faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import { SIDE_MENU_ITEMS } from "../../utils/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../../services/AuthService";
import {
	getUserData,
	updateUserAuthenticationStatus,
} from "../../utils/helper";
import { ROUTES } from "../../navigators/Routes";

import "./Sidebar.css";
import Logo from "../Logo";
import FooterButtons from "../FooterButtons";

const Sidebar = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [collapsed] = useState(false);
	const [activeSubMenuIds, setActiveSubMenuIds] = useState<string[]>([]);
	const [currentPath, setCurrentPath] = useState("");

	useEffect(() => {
		setCurrentPath(location.pathname);
	}, [location]);

	const toggleSubmenu = (id: string) => {
		if (activeSubMenuIds.includes(id)) {
			setActiveSubMenuIds((prevActiveSubMenuIds) =>
				prevActiveSubMenuIds.filter((activeSubMenuId) => activeSubMenuId !== id)
			);
		} else {
			setActiveSubMenuIds((prevActiveSubMenuIds) => [
				...prevActiveSubMenuIds,
				id,
			]);
		}
	};

	const onLogoutPress = async () => {
		try {
			const userData = await getUserData();
			console.log("userData :>> ", userData);

			if (!userData) {
				alert("User not found");
				return;
			}

			const response = await logoutUser({
				id: userData.id,
			});

			if (response.statusCode === 400) {
				alert(response.message);
				return;
			}

			await updateUserAuthenticationStatus(false, null);

			navigate(ROUTES.LOGIN, { replace: true });
		} catch (error) {
			console.log("Error in logging out", error);
		}
	};

	const onProfilePress = () => {
		navigate(ROUTES.PROFILE);
	};

	return (
		<div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
			<Logo />
			<CSSTransition
				in={!collapsed}
				timeout={300}
				classNames="sidebar-menu"
				unmountOnExit
			>
				<div>
					<ul className="sidebar-menu">
						{SIDE_MENU_ITEMS.map((item, index) => (
							<li className="sidebar-menu-item" key={item.id}>
								{item.subMenu && item.subMenu.length > 0 ? (
									<div className="w-100">
										<div
											style={{ width: "100%" }}
											onClick={() => toggleSubmenu(item.id)}
										>
											<div
												style={{
													padding: index !== 0 ? "20px 10px" : "20px 10px",
												}}
												className={
													"d-flex align-items-center justify-content-between rounded-3 parent-menu"
												}
											>
												<div>
													<FontAwesomeIcon icon={item.icon} />
													<span>{item.title}</span>
												</div>
												<FontAwesomeIcon
													icon={faCaretRight}
													size="sm"
													rotation={
														activeSubMenuIds.includes(item.id) ? 90 : undefined
													}
												/>
											</div>
										</div>
										{activeSubMenuIds.includes(item.id) && (
											<CSSTransition
												in={activeSubMenuIds.includes(item.id)}
												timeout={300}
												classNames="submenu"
												unmountOnExit
											>
												<ul className="submenu-items">
													{item.subMenu.map((subItem, index) => (
														<li
															className={`submenu-item rounded-3 ${
																currentPath === subItem.path
																	? "active-item"
																	: ""
															}`}
															key={index}
														>
															<NavLink
																to={subItem.path}
																style={{
																	width: "100%",
																}}
															>
																{subItem.title}
															</NavLink>
														</li>
													))}
												</ul>
											</CSSTransition>
										)}
									</div>
								) : (
									<NavLink
										to={item.path}
										className={`${
											currentPath === item.path ? "active-item" : ""
										} w-100 d-flex align-items-center rounded-3 parent-menu text-decoration-none`}
										style={{
											padding: index !== 0 ? "20px 10px" : "20px 10px",
										}}
									>
										<FontAwesomeIcon icon={item.icon} />
										<span>{item.title}</span>
									</NavLink>
								)}
							</li>
						))}
					</ul>
				</div>
			</CSSTransition>

			<div className="mt-auto mb-4">
				<FooterButtons
					title="Profile"
					icon={<FontAwesomeIcon icon={faUser} />}
					onClick={onProfilePress}
				/>
				<FooterButtons
					title="Logout"
					icon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
					onClick={onLogoutPress}
				/>
			</div>
		</div>
	);
};

export default Sidebar;
