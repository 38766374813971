import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Dashboard from "../Dashboard";
import { useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";

type Props = {};

const Main = (props: Props) => {
  const location = useLocation();

  const isRootPath = useMemo(() => {
    return location.pathname === "/";
  }, [location.pathname]);

  return (
    <div className="d-flex position-relative">
      <div
        style={{
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Sidebar />
      </div>
      <div
        style={{
          width: "85vw",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <AnimatePresence mode="wait">
          {isRootPath ? (
            <motion.div
              key="dashboard"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 50 }}
              transition={{ duration: 0.3 }}
            >
              {/* Render main page UI */}
              <Dashboard />
            </motion.div>
          ) : (
            <motion.div
              key={location.pathname}
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 50 }}
              transition={{ duration: 0.3 }}
            >
              {/* Render children routes */}
              <Outlet />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Main;
