import { Table } from "react-bootstrap";

import { images } from "../../assets";
import "./style.css";

const BillsPayables = () => {
  return (
    <Table className="transparent-table table-d">
      <thead className={`bg-light`}>
        <tr>
          <th className="py-3 text-secondary text-sm">Sr.No</th>
          <th className="py-3 text-secondary text-sm">Date</th>
          <th className="py-3 text-secondary text-sm">Bill Number</th>
          <th className="py-3 text-secondary text-sm">Product Name</th>
          <th className="py-3 text-secondary text-sm">Payment Mode</th>
          <th className="text-center p-3 text-secondary text-sm">
            Payment Status
          </th>
          <th className="text-center p-3 text-secondary text-sm">Remarks</th>
          <th className="text-center p-3 text-secondary text-sm">Amount</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 5 }).map((_, i) => {
          return (
            <tr key={i}>
              <td>01</td>
              <td>01/10/2024</td>
              <td>1542100011</td>
              <td>
                <div className="flex gap-2">
                  <img src={images.Rice} alt="" height={25} width={25} />
                  Sonamasuri Rice
                </div>
              </td>
              <td>Cash</td>
              <td className="text-danger">PENDING</td>
              <td>Refund</td>
              <td className="fw-bold">₹400.00</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default BillsPayables;
