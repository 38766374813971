import { useState, useEffect, useCallback } from "react";
import { Table, Form, Button, Image } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { images } from "../../assets";
import { getProducts } from "../../controller/ProductsController";

interface InventoryProps {
  selectedRadio1: boolean;
  items: any[];
  setItems: (items: any[]) => void;
}

const InventoryTable: React.FC<InventoryProps> = ({
  selectedRadio1,
  setItems,
  items,
}) => {
  const [localItems, setLocalItems] = useState<any[]>([]);
  const [Products, setProducts] = useState<any[]>([]);

  useEffect(() => {
    if (items && items.length > 0) {
      setLocalItems(items);
      console.log("LocalItems------>", items);
    }
  }, [items]);

  const getProductData = async () => {
    try {
      const response = await getProducts();
      const preparedData = response.data.map((item: any) => ({
        key: item.id,
        value: item.id,
        label: item.name,
        image: item.image,
        brand: item.brand.name,
      }));
      setProducts(preparedData);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const addNewRow = useCallback(() => {
    const newItem = {
      productId: null,
      quantityAvailable: "",
      newQuantityOnHand: "",
      quantityAdjusted: "",
      originalValue: "",
      newValue: "",
      valueAdjusted: "",
    };

    const updatedItems = [...localItems, newItem];
    setLocalItems(updatedItems);
    setItems(updatedItems); // Update parent state
  }, [localItems, setItems]);

  useEffect(() => {
    getProductData();
  }, []);

  useEffect(() => {
    if (items.length === 0) {
      addNewRow();
    }
  }, [items, addNewRow]);

  const handleItemChange = (index: number, field: string, value: any) => {
    const updatedItems = localItems.map((item, idx) =>
      idx === index ? { ...item, [field]: value } : item
    );
    setLocalItems(updatedItems);
    setItems(updatedItems); // Update parent state
  };

  const removeItem = (index: number) => {
    const updatedItems = localItems.filter((_, idx) => idx !== index);
    setLocalItems(updatedItems);
    setItems(updatedItems); // Update parent state
  };

  return (
    <>
      {selectedRadio1 ? (
        <Table bordered>
          <thead>
            <tr className="fw-semibold">
              <td>Item Details</td>
              <td>Quantity Available</td>
              <td>New Quantity On Hand</td>
              <td>Quantity Adjusted</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {localItems.map((item, idx) => (
              <tr key={idx} style={{ borderBottom: "10px solid #EFEFEF" }}>
                <td className="w-5">
                  <div className="d-flex align-items-center">
                    <Image
                      src={
                        item.productId
                          ? Products.find(
                              (prod: any) =>
                                prod.key.toString() ===
                                item.productId.toString()
                            )?.image || "placeholder.png"
                          : "placeholder.png"
                      }
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                      }}
                    />
                    <div className="w-100 flex align-items-center gap-1">
                      <Dropdown
                        className="w-100"
                        onSelect={(value) =>
                          handleItemChange(idx, "productId", value)
                        }
                      >
                        <Dropdown.Toggle
                          variant="light"
                          id="dropdown-custom-components"
                          className={`w-100 text-left custom-dropdown-toggle ${
                            !item.productId ? "text-secondary" : ""
                          }`}
                        >
                          {item.productId
                            ? Products.find(
                                (prod: any) =>
                                  prod.key.toString() ===
                                  item.productId.toString()
                              )?.label || "Select a product"
                            : "Type or select an item"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="w-100 bg-none">
                          {Products.map((prod: any) => (
                            <Dropdown.Item
                              key={prod.key}
                              eventKey={prod.value}
                              className="hover:bg-primary"
                            >
                              <div className="dropdown-item-container hover:text-white">
                                <div className="text-sm md:text-lg">
                                  {prod.label}
                                </div>
                                <div className="text-xs md:text-sm dropdown-item-subtext">
                                  {prod.brand}
                                </div>
                              </div>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className="flex gap-1">
                        <img src={images.more} alt="More" />
                        <img src={images.cancel} alt="Cancel" />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <Form.Control
                    type="text"
                    placeholder="0.00"
                    value={item.quantityAvailable}
                    onChange={(e) =>
                      handleItemChange(idx, "quantityAvailable", e.target.value)
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    placeholder="0.00"
                    value={item.newQuantityOnHand}
                    onChange={(e) =>
                      handleItemChange(idx, "newQuantityOnHand", e.target.value)
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    placeholder="Eg. +10, -10"
                    value={item.quantityAdjusted}
                    onChange={(e) =>
                      handleItemChange(idx, "quantityAdjusted", e.target.value)
                    }
                  />
                </td>
                <td>
                  <Button
                    variant=""
                    className="text-danger fw-bold text-xl"
                    onClick={() => removeItem(idx)}
                  >
                    X
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Table bordered>
          <thead>
            <tr className="fw-semibold">
              <td>Item Details</td>
              <td>Current Value</td>
              <td>Total Unit</td>
              <td>Adjusted Value</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {localItems.map((item, idx) => (
              <tr key={idx} style={{ borderBottom: "10px solid #EFEFEF" }}>
                <td className="w-5">
                  <div className="d-flex align-items-center">
                    <Image
                      src={
                        item.productId
                          ? Products.find(
                              (prod: any) =>
                                prod.key.toString() ===
                                item.productId.toString()
                            )?.image || "placeholder.png"
                          : "placeholder.png"
                      }
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                      }}
                    />
                    <div className="w-100 flex align-items-center gap-1">
                      <Dropdown
                        className="w-100"
                        onSelect={(value) =>
                          handleItemChange(idx, "productId", value)
                        }
                      >
                        <Dropdown.Toggle
                          variant="light"
                          id="dropdown-custom-components"
                          className={`w-100 text-left custom-dropdown-toggle ${
                            !item.productId ? "text-secondary" : ""
                          }`}
                        >
                          {item.productId
                            ? Products.find(
                                (prod: any) =>
                                  prod.key.toString() ===
                                  item.productId.toString()
                              )?.label || "Select a product"
                            : "Type or select an item"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="w-100 bg-none">
                          {Products.map((prod: any) => (
                            <Dropdown.Item key={prod.key} eventKey={prod.value}>
                              <div className="dropdown-item-container">
                                <div>{prod.label}</div>
                                <div className="dropdown-item-subtext">
                                  {prod.brand}
                                </div>
                              </div>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className="flex gap-1">
                        <img src={images.more} alt="More" />
                        <img src={images.cancel} alt="Cancel" />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <Form.Control
                    type="text"
                    placeholder="Current value should be (cost price x number of quantity)"
                    value={item.originalValue}
                    onChange={(e) =>
                      handleItemChange(idx, "originalValue", e.target.value)
                    }
                  />
                </td>
                <td className="border">
                  <Form.Control
                    type="text"
                    placeholder="0.00"
                    value={item.newValue}
                    onChange={(e) =>
                      handleItemChange(idx, "newValue", e.target.value)
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    placeholder="Eg. +10, -10"
                    value={item.valueAdjusted}
                    onChange={(e) =>
                      handleItemChange(idx, "valueAdjusted", e.target.value)
                    }
                  />
                </td>
                <td>
                  <Button
                    variant=""
                    className="text-danger fw-bold text-xl"
                    onClick={() => removeItem(idx)}
                  >
                    X
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <div className="p-2 px-4 mt-4">
        <button
          className="flex gap-3 align-items-center p-2 outline-none border rounded-3"
          style={{ backgroundColor: "#D7D7D7" }}
          onClick={addNewRow}
        >
          <FontAwesomeIcon
            icon={faPlus}
            size="sm"
            className="rounded-circle p-2 bg-primary"
          />
          Add New Row
        </button>
      </div>
    </>
  );
};

export default InventoryTable;
