import { useEffect, useState } from "react";

import { Image } from "primereact/image";
import { Button as PrimeReactButton } from "primereact/button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";

import RenderLottie from "../../components/RenderLottie";
import { Animations } from "../../animations";

import { images } from "../../assets";

import { colors } from "../../themes/colors";

import { addVendor, getVendors } from "../../controller/VendorController";
import { deleteFile, uploadFile } from "../../controller/FilesController";

import Loader from "../../components/Loader";
import TotalDetails from "../../components/TotalDetails/TotalDetails";
import Button from "../../components/Button";
import ViewBrandVendor from "../../components/ViewBrandvendor/ViewBrandVendor";
import Modal from "../../components/VendorModal/Modal";
import BoxInput from "../../components/BoxInput/BoxInput";

const BrandsVendor = () => {
  const [screenLoading, setScreenLoading] = useState(false);
  const [isSomethingWentWrong, setIsSomethingWentWrong] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [brandImage, setBrandImage] = useState<any>("");
  const [imageError] = useState("");

  const [allVendors, setAllVendors] = useState<Array<object>>([]);

  const [brandName, setBrandName] = useState<string>();
  const [paymentTerms, setPaymentTerms] = useState<string>("Pay On Receipt");
  const [eMail, setEMail] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [currency, setCurrency] = useState<string>("INR");
  const [webSite, setWebSite] = useState<string>("");

  const [modalShow, setModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const getData = async () => {
      try {
        setScreenLoading(true);
        const response = await getVendors();
        setAllVendors(response.data || []);
      } catch (error) {
        console.log(error);
        setIsSomethingWentWrong(true);
      } finally {
        setScreenLoading(false);
      }
    };

    getData();
  }, []);

  const validateInputs = () => {
    if (!brandName && !eMail && !address) {
      setErrorMessage(
        "Please Fill Required fields (Brand Name, E-Mail, Address)"
      );
    } else {
      setErrorMessage("");
      handleSave();
    }
  };

  const handleClose = () => {
    setModalShow(false);
  };

  const imageUpload = async () => {
    let preparedImage = new FormData();
    preparedImage.append("file", brandImage);
    const response = await uploadFile(preparedImage);
    return response.key;
  };

  const handleSave = async () => {
    try {
      setButtonLoading(true);
      const logoImage = brandImage && (await imageUpload());
      const preparedData = logoImage
        ? {
            name: brandName,
            address: address,
            email: eMail,
            paymentTerms: paymentTerms,
            currency: currency,
            logo: logoImage,
          }
        : {
            name: brandName,
            address: address,
            paymentTerms: paymentTerms,
            currency: currency,
            email: eMail,
          };
      const response = await addVendor(preparedData);
      console.log(response);
    } catch (error) {
      console.log("error====>", error);
    } finally {
      setButtonLoading(false);
      setModalShow(false);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newImages: Blob[] = Array.from(files);
      setBrandImage(newImages[0]);
    }
  };

  const onDeleteImage = async () => {
    try {
      await deleteFile(brandImage);
      setBrandImage("");
    } catch (error) {
      console.log(error);
    }
  };

  return screenLoading ? (
    <Loader />
  ) : isSomethingWentWrong ? (
    <RenderLottie
      animationData={Animations.SomethingWentWrong}
      title="Something Went Wrong"
      message="Please try again later or contact support for assistance."
    />
  ) : (
    <div>
      <div className="p-3">
        {/* Page Name */}
        <h4 className="mb-4 fw-bold">Brands (Vendor)</h4>
        {/* Total details */}
        <TotalDetails
          Items={[
            {
              backgroundColor: colors.bgLightBlue,
              img: images.contact,
              title: "Total Vendors (Brands)",
              value: "120",
            },
            {
              backgroundColor: colors.bgLightPink,
              img: images.money,
              title: "Payables",
              value: "₹ 12,000",
            },
            {
              backgroundColor: colors.bgGreen,
              img: images.pending,
              title: "Purchase Order Pending",
              value: "100",
            },
          ]}
        />
        {/* Search and AddVendor button */}
        <div className="d-flex justify-content-between align-items-center	my-5">
          <div className="search-container" style={{ width: "35%" }}>
            <input
              type="search"
              className="search-input outline-none border rounded-3 p-2 px-3"
              placeholder="Search Brand"
            />
            <FontAwesomeIcon
              icon={faSearch}
              color={colors.grayIcon}
              className="mr-2 search-icon"
            />
          </div>

          <div className="flex">
            <Button
              variant=""
              className="bg-black text-white px-4"
              onClick={() => {
                setModalShow(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add Vendor
            </Button>
          </div>
        </div>
        {/* View All vendors */}
        {allVendors.length > 0 ? (
          <ViewBrandVendor allVendors={allVendors} />
        ) : (
          <RenderLottie
            animationData={Animations.NoDataFound}
            title="No Vendor Found"
            message="Please add Vendor"
          />
        )}
      </div>
      <Modal
        title="Add Vendor"
        show={modalShow}
        handleClose={handleClose}
        handleSave={validateInputs}
        loading={buttonLoading}
        size="lg"
        body={
          <div className="flex gap-4 flex-column">
            {errorMessage !== "" && (
              <span className="text-sm text-danger">{errorMessage}</span>
            )}
            {/* Brand Name & Payment Terms */}
            <div className="flex gap-4">
              <div className="w-50">
                <BoxInput
                  label="Brand Name"
                  placeHolder="Enter Brand Name"
                  handleChange={setBrandName}
                  value={brandName}
                />
              </div>
              <div className="w-50">
                <BoxInput
                  label="Payment Terms"
                  placeHolder="Payment Terms"
                  handleChange={setPaymentTerms}
                  value={paymentTerms}
                  readOnly={true}
                />
              </div>
            </div>
            {/* Email & Currency */}
            <div className="flex gap-4">
              <div className="w-50">
                <BoxInput
                  label="E-Mail Address"
                  placeHolder="Add E-mail Address"
                  handleChange={setEMail}
                  value={eMail}
                />
              </div>
              <div className="w-50">
                <BoxInput
                  label="Currency"
                  placeHolder="Currency"
                  handleChange={setCurrency}
                  value={currency}
                  readOnly={true}
                />
              </div>
            </div>
            {/* Address & Website Link */}
            <div className="flex gap-4">
              <div className="w-50">
                <BoxInput
                  label="Address"
                  placeHolder="Add Address"
                  handleChange={setAddress}
                  value={address}
                />
              </div>
              <div className="w-50">
                <BoxInput
                  label="Website"
                  placeHolder="Website Link"
                  handleChange={setWebSite}
                  value={webSite}
                />
              </div>
            </div>
            {/* Brand Image */}
            <div
              className="flex flex-column rounded-3 p-2 justify-content-between"
              style={{ border: "1px solid #E1E1E1" }}
            >
              <span
                className="text-sm fw-semibold"
                style={{ color: "#6C757D" }}
              >
                Brand Logo
              </span>
              <div className="h-100">
                {!brandImage ? (
                  <div>
                    <label
                      htmlFor="image-upload"
                      className="border-dashed border-light-subtle rounded d-flex flex-column justify-content-center align-items-center bg-white"
                      style={{
                        height: "200px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        id="image-upload"
                        style={{ visibility: "hidden" }}
                      />
                      <FontAwesomeIcon icon={faCloudArrowUp} size="2xl" />
                      <p className="w-75 text-center text-secondary">
                        Drag and drop your image here or click to upload
                      </p>
                    </label>
                    {imageError && (
                      <small className="text-danger">{imageError}</small>
                    )}
                  </div>
                ) : (
                  <div className="image-upload">
                    <div className="image-preview">
                      <div className="">
                        <Image
                          src={URL.createObjectURL(brandImage)}
                          alt={`Brand`}
                          width="300"
                          height="200"
                          preview
                          style={{
                            objectFit: "contain",
                          }}
                        />
                        <PrimeReactButton
                          icon="pi pi-trash"
                          className="p-button-rounded p-button-danger p-button-outlined p-button-sm p-button-text"
                          onClick={onDeleteImage}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default BrandsVendor;
