import { Table } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";

const CustomerTable = () => {
  return (
    <div className="table-container">
      <Table className="transparent-table">
        <thead className={`bg-light`}>
          <tr>
            <th className="py-3 text-secondary text-sm">Sr.No</th>
            <th className="py-3 text-secondary text-sm">Customer Name</th>
            <th className="py-3 text-secondary text-sm">Contact Number</th>
            <th className="py-3 text-secondary text-sm">E-Mail Address</th>
            <th className="py-3 text-secondary text-sm">Created Date</th>
            <th className="text-center p-3 text-secondary text-sm">
              Receivables
            </th>
            <th className="text-center p-3 text-secondary text-sm">Payables</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01</td>
            <td>
              Arshad Qureshi
              <br />
              <div className="text-xs text-secondary">
                Customer ID : 1201920101
              </div>
            </td>
            <td>+91 9992101011</td>
            <td>thearshadq@gmail.com</td>
            <td>09 Aug 2024</td>
            <td>
              <div className="flex flex-column align-items-center text-success fw-bold">
                ₹400.00
                <div className="text-xs text-success fw-bold">
                  You collect <FontAwesomeIcon icon={faArrowDown} />
                </div>
              </div>
            </td>
            <td>
              <div className="flex flex-column align-items-center text-danger fw-bold">
                <div>₹400.00</div>
                <div className="text-xs text-danger fw-bold">
                  You Pay <FontAwesomeIcon icon={faArrowUp} />
                </div>
              </div>
            </td>
            <td>
              <div className="flex align-items-center">
                <FontAwesomeIcon
                  icon={faEllipsis}
                  className="rounded-2 bg-light text-secondary text-xl p-1 px-2"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default CustomerTable;
