import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../navigators/Routes";

import { images } from "../../assets";

import { POHeader } from "../../components/PurchaseOrderComponents";
import NavTabs from "../../components/NavTabs/NavTabs";
import SearchInput from "../../components/SearchInput/SearchInput";
import FilterButton from "../../components/Filterbutton/FilterButton";
import { CustomerTable } from "../../components/CustomerComponent/index";

const Customer = () => {
  const navigate = useNavigate();

  const [active, setActive] = useState("All Customers");

  const handleNewCustomer = () => {
    navigate(ROUTES.ADD_CUSTOMER);
  };

  return (
    <>
      <POHeader
        title="Customers"
        buttonName={"Add New Customer"}
        onButtonClick={handleNewCustomer}
      />
      <div className="p-3 flex flex-column gap-4">
        <NavTabs
          tabs={["All Customers", "Regular Customer", "Deleted Customer"]}
          activeTab={active}
          setActiveTab={setActive}
        />
        <div className="flex justify-content-between">
          <SearchInput
            placeholder="Search customer by name, mobile number, email address etc...."
            width="35%"
          />
          <FilterButton
            titles={["Recently Added", "Oldest"]}
            Name={"Filter By"}
            images={[images.Oldest, images.Recent]}
          />
        </div>
        <div style={{ height: "55vh" }}>
          <CustomerTable />
        </div>
        <div className="flex gap-3">
          <div className="text-center p-1 border px-3 text-success border-success bg-success-subtle rounded-3">
            Total Receivables ₹11,180
          </div>
          <div className="text-center p-1 border px-3 text-danger border-danger bg-danger-subtle  rounded-3">
            Total Payables ₹1,121
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
