import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Table, Image } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { faIndianRupee, faPlus } from "@fortawesome/free-solid-svg-icons";

import { ROUTES } from "../../navigators/Routes";

import { images } from "../../assets";

import AllOrdersSideBar from "../../components/SalesOrdersComponents/AllOrdersSidebar";
import VerticalInput from "../../components/VerticalInput/VerticalInput";
import SearchInput from "../../components/SearchInput/SearchInput";
import PaymentBreakDown from "../../components/PaymentBreakDown/PaymentBreakDown";
import PaymentDescription from "../../components/PaymentDescription/PaymentDescripition";
import DeletePopUp from "../../components/DeletePopUp/DeletePopUp";

const AllSalesOrders = () => {
  const navigate = useNavigate();

  const [orderExchange, setOrderExchange] = useState(false);
  const [refund, setRefund] = useState(false);

  const [qty, setQty] = useState();

  const [showDelete, setShowDelete] = useState(false);

  const handleCancel = () => {
    setOrderExchange(false);
    setRefund(false);
  };

  const handleExchange = () => {
    setOrderExchange(true);
  };

  const handleRefund = () => {
    setRefund(true);
  };

  const handleSave = () => {
    navigate(ROUTES.All_ORDERS);
  };

  return (
    <div className="flex gap-1">
      <div className="w-3">
        <AllOrdersSideBar />
      </div>
      <div className="p-3 w-9 border shadow flex flex-column gap-3">
        {/* header */}
        <div className="flex justify-content-between">
          <h4 className="fw-bold">SO-0001</h4>
          {!orderExchange && !refund ? (
            <div className="flex gap-3">
              <Button className="bg-transparent border text-sm border-2 text-secondary flex gap-2 align-items-center rounded-3">
                <FontAwesomeIcon icon={faFilePdf} />
                Download
              </Button>
              <Button className="bg-transparent border text-sm border-2 text-secondary flex gap-2 align-items-center rounded-3">
                Update Order Status
              </Button>
              <button
                className="text-danger border text-sm border-2 delay flex gap-2 align-items-center rounded-3 border-danger p-2"
                onClick={handleExchange}
              >
                ORDER EXCHANGE
              </button>
              <Button
                variant="danger"
                className="border text-sm border-2 flex gap-2 align-items-center rounded-3 border-danger"
                onClick={handleRefund}
              >
                REFUND
              </Button>
            </div>
          ) : (
            <Button
              className="bg-transparent text-sm rounded-3 border-danger text-danger px-3"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          )}
        </div>

        {/* What's Next */}
        <div className="flex gap-2">
          <img src={images.QRcode} alt="QRCode" />
          <div className="flex flex-column justify-content-center">
            <h5 className="fw-bold">WHAT’S NEW?</h5>
            <div className="flex gap-3 align-items-center">
              <div className="text-sm">
                Create loyalty program/discount coupons to retain customers
              </div>
              <div>
                <button className="btn bg-primary text-sm rounded-3">
                  Create Offer
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Bills Info */}
        <div className="p-3 bg-light rounded-4">
          <h2 className="fw-semibold text-lg mb-4">Bill</h2>
          <div className="flex justify-content-between gap-3 mb-3">
            <div className="w-25">
              <div className="text-sm">
                <label className="form-label fw-semibold text-muted">
                  Bill Status
                </label>
              </div>
              <div className="success p-2 rounded-3 text-center text-sm">
                PAID
              </div>
            </div>
            <div className="w-25">
              <VerticalInput
                label="Bill Number"
                value={"0001"}
                placeHolder=""
              />
            </div>
            <div className="w-25">
              <VerticalInput
                label="Bill Date"
                value={"05-10-2024"}
                placeHolder=""
              />
            </div>
            <div className="w-25">
              <VerticalInput
                label="Bill Due Date"
                value={"05-10-2024"}
                placeHolder=""
              />
            </div>
          </div>
          <div className="flex justify-content-between gap-4">
            <div className="w-25">
              <VerticalInput
                label="Sales Order Number"
                value={"120101"}
                placeHolder=""
              />
            </div>
            <div className="w-25">
              <VerticalInput
                label="Payment Terms"
                value={"Due On Receipt"}
                placeHolder=""
              />
            </div>
            <div className="w-25">
              <VerticalInput label="Balance Due" value={"0"} placeHolder="" />
            </div>
            <div className="w-25">
              <VerticalInput
                label="Total Amount (in ₹)"
                value={"1395.00"}
                placeHolder=""
                inputClass="bg-body-secondary"
              />
            </div>
          </div>
        </div>

        {/* Exchange Reason */}
        {orderExchange && (
          <div>
            <h5 className="text-2xl fw-bold">Exchange Reason</h5>
            <textarea
              name="reason"
              rows={5}
              className="w-100 p-1 outline-none border rounded-3"
            ></textarea>
          </div>
        )}

        {/* Customer Info*/}
        {(!orderExchange && !refund) && (
          <div>
            <h6 className="fw-semibold text-lg mb-2">Customer Information</h6>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <VerticalInput
                  label="Contact Number"
                  value={"+91 9192111023"}
                />
              </div>
              <div className="col-md-6 col-lg-3">
                <VerticalInput
                  label="Customer ID"
                  value={"12345694"}
                  inputClass="bg-light"
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 col-lg-3">
                <VerticalInput
                  label="Name"
                  value={"Arshad Qureshi"}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 col-lg-3">
                <VerticalInput label="E-Mail" value={"abc@gmail.com"} />
              </div>
            </div>
          </div>
        )}

        {/* Sales Type */}
        {(!orderExchange && !refund) && (
          <div>
            <h6 className="fw-semibold text-lg mb-2">Sales Type</h6>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <VerticalInput
                  label="Type of Sale"
                  value={"Online"}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 col-lg-3">
                <VerticalInput
                  label="Expected Delivery"
                  value={"30/12/2024"}
                  readOnly={true}
                />
              </div>
              <div className="w-25">
                <div className="text-sm">
                  <label className="form-label fw-semibold text-muted">
                    Order Status
                  </label>
                </div>
                <div className="success p-2 rounded-3 text-sm">RECEIVED</div>
              </div>
            </div>
          </div>
        )}

        {/* Product Info */}
        <div>
          <h6 className="fw-semibold text-lg mb-2">Product Information</h6>
          <div className="bg-light rounded-4 p-2">
            <Table className="p-table transparent-table">
              <thead className={`text-sm rounded-t-3`}>
                <tr>
                  <td className="w-7">Item Details</td>
                  <td>Sales Order Quantity</td>
                  <td>Amount (in ₹)</td>
                </tr>
              </thead>
              <tbody>
                <tr className="border-none">
                  <td className="w-7">
                    <div className="d-flex align-items-center">
                      <Image
                        src={images.Rice}
                        height={45}
                        width={45}
                        className="border rounded-3 border-2"
                        style={{
                          marginRight: "10px",
                        }}
                      />
                      <div>Organic Sonamasuri Brown Rice</div>
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control w-50"
                      value={"2"}
                    />
                  </td>
                  <td className="font-bold">700</td>
                </tr>
              </tbody>
            </Table>
            <div className="flex justify-content-between text-base">
              <strong>Total Amount</strong>
              <strong>₹1,395.00</strong>
            </div>
          </div>
        </div>

        {/* Exchange Product Info */}
        {(orderExchange || refund) && (
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h6 className="fw-semibold text-lg">
                Exchange Product Information
              </h6>
              <a href="#/" className="text-xs fw-semibold text-decoration-none">
                Add new Product?
              </a>
            </div>
            <div className="flex gap-3">
              <SearchInput placeholder="Search for existing product" />
              <div>
                <input
                  type="number"
                  value={qty}
                  onChange={(e: any) => {
                    setQty(e.target.value);
                  }}
                  className="form-control text-sm"
                  placeholder={"Qty"}
                />
              </div>
              <Button variant="dark" className="rounded-3 text-sm">
                Add to Bill <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
            <Table className="p-table transparent-table mt-3">
              <thead className={`bg-light text-sm rounded-t-3`}>
                <tr>
                  <td>Item Details</td>
                  <td>Sales Order Quantity</td>
                  <td>Cost Price (in ₹)</td>
                  <td>Amount (in₹)</td>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom table-tr">
                  <td className="w-5">
                    <div className="d-flex align-items-center">
                      <Image
                        src={images.Rice}
                        height={45}
                        width={45}
                        className="border rounded-3 border-2"
                        style={{
                          marginRight: "10px",
                        }}
                      />
                      <div className="row">
                        <div className="w-100 text-sm flex align-items-center justify-content-between gap-1">
                          Organic Sonamasuri Brown Rice
                        </div>
                        <div className="text-xs text-secondary fw-bold">
                          Stock in hand : 25
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control w-50"
                      value={"2"}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control w-50"
                      value={350}
                    />
                  </td>
                  <td className="font-bold">700</td>
                </tr>
              </tbody>
            </Table>
          </div>
        )}

        {/* Payment terms */}
        {(orderExchange || refund) && (
          <div className="flex gap-3 align-items-center">
            <div className="text-sm text-secondary w-50">
              <span className="fw-bold mr-2">Note:</span>All products ordered
              must meet the specified specifications and quality requirements as
              detailed in purchase order.
            </div>
            <div className="w-50">
              <PaymentDescription />
            </div>
          </div>
        )}

        {orderExchange && (
          <div>
            <h6 className="fw-semibold text-lg mb-2">Payment Difference</h6>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <VerticalInput
                  label="Previous Total (in ₹)"
                  value={"1395.00"}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 col-lg-3">
                <VerticalInput
                  label="New Total (in ₹)"
                  value={"1700.00"}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 col-lg-3">
                <VerticalInput
                  label="You Collect (in ₹)"
                  value={"305.00"}
                  inputClass="text-success fw-bold"
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 col-lg-3">
                <VerticalInput
                  label="You Give (in ₹)"
                  value={"0"}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        )}

        {/* Payment BreakDown */}
        <div className="mb-8">
          <PaymentBreakDown />
        </div>

        {/* Bottom Bar */}
        {(orderExchange || refund) && (
          <div
            className="p-2 b-bar px-3 bg-white rounded-3 shadow flex justify-content-between align-items-center text-base"
            style={{ width: "60%" }}
          >
            <div className="flex gap-3 fw-bold">
              <div className="text-secondary">Total</div>
              <div>₹305.00</div>
            </div>
            <div className="flex gap-4">
              <Button
                variant=""
                className="rounded-3 cancel px-4 text-sm"
                onClick={() => {
                  setShowDelete(true);
                }}
              >
                Cancel
              </Button>
              <Button variant="" className="rounded-3 draft text-sm">
                Save as Draft
              </Button>
              {orderExchange && (
                <Button
                  variant=""
                  className="rounded-3 hover:bg-primary text-sm bg-dark text-white"
                  onClick={handleSave}
                >
                  Save & Record Payment <FontAwesomeIcon icon={faIndianRupee} />
                </Button>
              )}
              {refund && (
                <button
                  className="text-danger border text-sm border-2 delay flex gap-2 align-items-center rounded-3 border-danger p-2"
                  onClick={handleExchange}
                >
                  Process & Record Refund{" "}
                  <FontAwesomeIcon icon={faIndianRupee} />
                </button>
              )}
            </div>
          </div>
        )}

        {/* Cancel PopUp */}
        <DeletePopUp
          btnName={"Save as Draft"}
          show={showDelete}
          body={
            "Any unsaved changes will be lost. Do you want to save them in drafts?"
          }
          handleClose={() => {
            setShowDelete(false);
          }}
        />
      </div>
    </div>
  );
};

export default AllSalesOrders;
