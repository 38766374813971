import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";

type Props = {
  titles: Array<String>;
  Name: String;
  images?: Array<string>;
};
const FilterButton = (props: Props) => {
  const { titles, Name, images } = props;
  const [selectedItem, setSelectedItem] = useState<String>(Name);
  const handleSelect = (item: String) => {
    setSelectedItem(item);
  };
  return (
    <DropdownButton
      variant=""
      className="ml-3 border border-2 rounded"
      title={
        <>
          <FontAwesomeIcon icon={faFilter} className="mr-2" />
          {selectedItem}
        </>
      }
    >
      {titles.map((item, i) => {
        return (
          <Dropdown.Item
            key={i}
            className="item flex gap-3 align-items-center"
            onClick={() => handleSelect(item)}
          >
            {images && <img src={images[i]} alt="" height={15} width={15} />}
            {item}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

export default FilterButton;
