export const colors = {
  lightBlack: "#1a1a1a",
  grey: "#808080",
  blue: "#006eff",
  red: "#ff0000",
  white: "#ffffff",
  pink: "#FFD3F5",
  aqua: "#7BF1A8",
  lightPurple: "#D3D7FF",
  purple: "#9381FF",
  lightOrange: "#FFCE85",
  grayIcon: "#9F9F9F",

  bgLightBlue:"#BDD3FF",
  bgLightPink:"#FFDCDC",
  bgGreen:"#426E27",

  greenGradient: "linear-gradient(201.18deg, #D3FFE7 3.14%, #EFFFF6 86.04%)",
  blueGradient: "linear-gradient(201.18deg, #CAF1FF 3.14%, #CDF4FF 86.04%)",
};
