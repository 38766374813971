export const PAYMENT_TERMS: any = {
  "Due on Receipt": "DOR",
  "NET 15": "NET15",
  "NET 30": "NET30",
  "NET 45": "NET45",
  "NET 60": "NET60",
  "END OF MONTH": "EOM",
  "END OF NEXT MONTH": "EONM",
  "CUSTOM DATE": "CD",
};

export const PAYMENT_MODE: any = {
  CASH: "C",
  UPI: "U",
  "Debit Card": "D",
  "Credit Card": "CC",
  "Net Banking": "NB",
};

export const DISCOUNT_AMOUNT_TYPES: any = {
  "%": "PG",
  "₹": "VE",
};

export const ORDER_STATUS = {
  DRAFT: "DR",
  ON_PROCESS: "OP",
  CANCELLED: "CA",
  RECEIVED: "RE",
  DELAYED: "DE",
};

export const PAYMENT_STATUS = {
  DRAFT: "DR",
  PENDING: "PE",
  PAID: "PA",
  OVERDUE: "OV",
  CANCELLED: "CA",
};
