import { Table } from "react-bootstrap";

import "./style.css";

const Promotions = () => {
  return (
    <Table className="transparent-table table-d">
      <thead className={`bg-light`}>
        <tr>
          <th className="py-3 text-secondary text-sm">Sr.No</th>
          <th className="py-3 text-secondary text-sm">Date</th>
          <th className="py-3 text-secondary text-sm">Promotion Name</th>
          <th className="py-3 text-secondary text-sm">Promotion Status</th>
          <th className="py-3 text-secondary text-sm">Action</th>
          <th className="text-center p-3 text-secondary text-sm">Code</th>
          <th className="text-center p-3 text-secondary text-sm">
            Amount (in ₹)
          </th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 5 }).map((_, i) => {
          return (
            <tr key={i}>
              <td>01</td>
              <td>
                <div className="flex flex-column">
                  01/10/2024
                  <small className="text-secondary">Expires : 10/12/2024</small>
                </div>
              </td>
              <td>Diwali Season</td>
              <td className="uppercase text-primary fw-bold">open</td>
              <td>
                <div className="delay p-1 rounded-3 fw-bold text-sm">
                  NOT-REDEEMED
                </div>
              </td>
              <td>FLAT20</td>
              <td>
                <div className="fw-bold">
                  -<div className="text-xs text-secondary">Total : -</div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default Promotions;
