import React from "react";
import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

const AllOrdersSideBar: React.FC = () => {
  return (
    <div className="py-3 shadow h-100 border">
      <div className="d-flex justify-content-between align-items-center mb-3 px-2">
        <h5 className="text-xl fw-bold">
          Sales Order <FontAwesomeIcon icon={faChevronDown} />
        </h5>
        <Button variant="dark" className="rounded-3 text-sm">
          New SO <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </Button>
      </div>

      <div className="flex flex-column grey-hover gap-2 p-3 border-bottom">
        <div className="rounded d-flex justify-content-between align-items-center">
          <div className="flex gap-2 align-items-center">
            <input type="checkbox" name="" id="" />
            <div className="fw-bold">R.K. Retail Store</div>
          </div>
          <span className="fw-bold text-lg">₹ 16,000</span>
        </div>
        <div className="text-muted text-sm">
          PO - 00002 &nbsp;&nbsp; 05/04/2024
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className={`badge success w-5`}>Received</div>
          <div className={`badge success w-5`}>Paid</div>
        </div>
      </div>
      <div className="flex flex-column grey-hover gap-2 p-3 border-bottom">
        <div className="rounded d-flex justify-content-between align-items-center">
          <div className="flex gap-2 align-items-center">
            <input type="checkbox" name="" id="" />
            <div className="fw-bold">R.K. Retail Store</div>
          </div>
          <span className="fw-bold text-lg">₹ 16,000</span>
        </div>
        <div className="text-muted text-sm">
          PO - 00002 &nbsp;&nbsp; 05/04/2024
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className={`badge onProcess w-5`}>ON PROCESS</div>
          <div className={`badge pending w-5`}>Pending</div>
        </div>
      </div>
      <div className="flex flex-column grey-hover gap-2 p-3 border-bottom">
        <div className="rounded d-flex justify-content-between align-items-center">
          <div className="flex gap-2 align-items-center">
            <input type="checkbox" name="" id="" />
            <div className="fw-bold">R.K. Retail Store</div>
          </div>
          <span className="fw-bold text-lg">₹ 16,000</span>
        </div>
        <div className="text-muted text-sm">
          PO - 00002 &nbsp;&nbsp; 05/04/2024
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className={`badge draft w-5`}>DRAFT</div>
          <div className={`badge delay w-5`}>OVERDUE</div>
        </div>
      </div>
    </div>
  );
};

export default AllOrdersSideBar;
