export const END_POINTS = {
  // Auth
  USER_LOGIN: "api/merchants/user/login",
  USER_REGISTER: "api/merchants/user/register",
  USER_LOGOUT: "merchant/logout",

  // Category
  ADD_CATEGORY: "api/merchants/categories/create",
  ADD_SUB_CATEGORY: "api/merchants/sub-categories/create",
  MERCHANT_CATEGORIES: "api/merchants/categories",
  GET_SUB_CATEGORIES: "api/merchants/sub-categories",
  UPDATE_CATEGORY: "api/merchants/categories/",
  UPDATE_SUB_CATEGORY: "api/merchants/sub-categories/",
  DELETE_CATEGORY: "api/merchants/categories/",
  DELETE_SUB_CATEGORY: "api/merchants/sub-categories/",

  // Product
  ADD_PRODUCT: "api/merchants/products/create",
  UPDATE_PRODUCT: "api/merchants/products/",
  GET_PRODUCTS: "api/merchants/products",
  GET_SINGLE_PRODUCT: "api/merchants/products/",
  DELETE_PRODUCT: "api/merchants/products/",
  GET_PRODUCT_SUMMARY: "api/merchants/products/summary",

  // Merchant
  ADD_BRAND: "api/merchants/brands/create",
  GET_BRAND: "api/merchants/brands/",
  UPDATE_BRAND: "api/merchants/brands/",
  DELETE_BRAND: "api/merchants/brands/",

  // Adjustments
  ADD_ADJUSTMENT: "api/merchants/adjustment/create",
  GET_ADJUSTMENTS: "api/merchants/adjustment",
  DELETE_ADJUSTMENTS: "api/merchants/adjustment/",
  GET_SINGLE_ADJUSTMENTS: "api/merchants/adjustment/",
  UPDATE_ADJUSTMENTS: "api/merchants/adjustment/",

  // Vendor
  ADD_VENDOR: "api/merchants/vendors/create",
  ADD_CONTACT_DETAILS: "api/merchants/vendors/contacts/create",
  ADD_BANK_DETAILS: "api/merchants/vendors/bank-details/create",
  UPDATE_VENDOR: "api/merchants/vendors/",
  UPDATE_CONTACT: "api/merchants/vendors/contacts/",
  GET_VENDORS: "api/merchants/vendors",
  GET_SINGLE_VENDOR: "api/merchants/vendors/",

  // PURCHASE ORDER
  GET_PO_NO: "api/merchants/po/new-po-number",
  GET_BILL_NO: "api/merchants/bills/new-bill-number",
  CREATE_PO: "api/merchants/po/create",

  // Files
  ADD_FILE: "files/upload",
};
