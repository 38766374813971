import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./style.css";

const PaymentBreakDown = () => {
  return (
    <div className="w-7">
      <h6 className="fw-semibold text-lg">
        Payment Break down <FontAwesomeIcon icon={faCircleInfo} />
      </h6>
      <table className="table transparent-table border-white pbd-table -mb-1">
        <thead className={`text-sm bg-body-secondary`}>
          <tr>
            <td>Installment</td>
            <td>Date</td>
            <td>Payment Mode</td>
            <td>Amount (in ₹)</td>
          </tr>
        </thead>
        <tbody className="text-sm">
          <tr className="bg-light">
            <td>1</td>
            <td>13/05/2024</td>
            <td>UPI</td>
            <td>₹1200.00</td>
          </tr>
          <tr className="bg-light">
            <td>2</td>
            <td>13/05/2024</td>
            <td>CASH</td>
            <td>195</td>
          </tr>
        </tbody>
      </table>
      <div className="flex justify-content-between bg-body-secondary mt-2 px-2 p-1">
        <strong className="text-sm text-secondary">Balance Due</strong>
        <strong className="text-sm text-secondary">0.00</strong>
      </div>
      <div className="flex justify-content-between bg-body-secondary my-1 px-2 p-1">
        <strong className="text-sm">Total</strong>
        <strong className="text-sm">1395.00</strong>
      </div>
    </div>
  );
};

export default PaymentBreakDown;
