import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

import { images } from "../../assets";

import {
  AllCustomerSidebar,
  PurchaseHistoryTable,
  BillReceivables,
  BillsPayables,
  ERtable,
  Promotions,
} from "../../components/CustomerComponent/index";
import VerticalInput from "../../components/VerticalInput/VerticalInput";
import NavTabs from "../../components/NavTabs/NavTabs";

import "./style.css";

const AllCustomers = () => {
  const [active, setActive] = useState("Purchase History");
  return (
    <div className="flex gap-1">
      <div className="w-3 vh-100">
        <AllCustomerSidebar />
      </div>
      <div className="p-4 shadow w-9 flex flex-column gap-4">
        {/* Header */}
        <div className="flex justify-content-between">
          <div className="flex flex-column gap-2">
            <h4 className="fw-bold">Arshad Qureshi</h4>
            <div className="flex gap-3 text-xs">
              <div>
                Customer ID : <span className="fw-bold">1201920101</span>
              </div>
              <div>
                Receivables : <span className="text-secondary">₹0.00</span>
              </div>
            </div>
            <div className="flex gap-3 text-xs">
              <div>
                Date of Birth : <span className="fw-bold">10/06/2001</span>
              </div>
              <div>
                Payables : <span className="text-danger fw-bold">₹400.00</span>
              </div>
            </div>
          </div>
          <div className="flex justify-content-end gap-2">
            <div className="w-7 flex flex-column align-items-end">
              <h5 className="fw-bold">WHAT’S NEW?</h5>
              <p className="text-sm text-right">
                Create loyalty program/discount coupons to retain customers
              </p>
              <button className="btn bg-primary text-sm rounded-3">
                Create Offer
              </button>
            </div>
            <img src={images.QRcode} alt="QRCode" />
          </div>
        </div>
        {/* Customer Info */}
        <div>
          <div className="flex justify-content-between">
            <h6 className="fw-semibold mb-4 text-lg">Customer Information</h6>
            <span className="text-xs text-primary fw-bold">
              Edit
              <FontAwesomeIcon icon={faPenToSquare} className="ml-1" />
            </span>
          </div>
          <div className="flex gap-4 justify-content-between">
            <div className="w-4">
              <VerticalInput
                label="Name"
                value={"Arshad Qureshi"}
                readOnly={true}
                inputClass="bg-body-secondary"
              />
            </div>
            <div className="w-4">
              <VerticalInput
                label="E-Mail"
                value={"abc@gmail.com"}
                readOnly={true}
                inputClass="bg-body-secondary"
              />
            </div>
            <div className="w-4">
              <VerticalInput
                label="Contact Number"
                value={"+91 9192111023"}
                readOnly={true}
                inputClass="bg-body-secondary"
              />
            </div>
          </div>
          <div className="flex gap-4 justify-content-between mt-3">
            <div className="w-4">
              <VerticalInput
                label="Billing Address"
                value={"14, Rajasthan Society, Shahalam...."}
                readOnly={true}
                inputClass="bg-body-secondary"
              />
            </div>
            <div className="w-4">
              <VerticalInput
                label="Shipping Address"
                value={"14, Rajasthan Society, Shahalam...."}
                readOnly={true}
                inputClass="bg-body-secondary"
              />
            </div>
            <div className="w-4">
              <VerticalInput
                label="Credit Limit"
                value={"0"}
                readOnly={true}
                inputClass="bg-body-secondary"
              />
            </div>
          </div>
        </div>
        <NavTabs
          tabs={[
            "Purchase History",
            "Bill Receivables",
            "Bills Payables",
            "Exchange/Refund",
            "Promotions",
          ]}
          activeTab={active}
          setActiveTab={setActive}
        />

        <div className="show-table">
          {active === "Purchase History" && <PurchaseHistoryTable />}
          {active === "Bill Receivables" && <BillReceivables />}
          {active === "Bills Payables" && <BillsPayables />}
          {active === "Exchange/Refund" && <ERtable />}
          {active === "Promotions" && <Promotions />}
        </div>
      </div>
    </div>
  );
};

export default AllCustomers;
