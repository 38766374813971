export const ROUTES = {
  MAIN: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  PRODUCTS: "/products",
  CATEGORIES: "/categories",
  BRANDS: "/brands",
  ADD_EDIT_PRODUCT: "/products/add-edit",
  ADJUSTMENTS: "/adjustments",
  ADD_ADJUSTMENTS: "/adjustments/add-edit",
  VENDOR: "/vendor",
  VENDOR_DETAILS: "/vendor-details",
  VENDOR_DETAILS_ADD_PRODUCT: "/vendor-details/add-product",
  PURCHASE_ORDERS: "/purchase-orders",
  NEW_PURCHASE_ORDERS: "/new-purchase-order",
  EDIT_PURCHASE_ORDER: "/purchase-orders/edit-orders",
  SENDMAIL: "/new-purchase-order/send-mail",
  ALL_PURCHASE_ORDERS: "/all-purchase-orders",
  BILLS: "/allBills",
  PROFILE: "/profile",
  CUSTOMERS: "/customers",
  ADD_CUSTOMER: "/add-customer",
  ALL_CUSTOMERS: "/all-customer",
  ORDERS: "/orders",
  NEW_ORDER: "/new-order",
  All_ORDERS: "/all-orders",
  DISCOVER_BRANDS: "/discover-brands",

  ENC_DEC: "/Enc_Dec",
};
