type Props = {
	title: string;
	icon: any;
	onClick: () => void;
};

const FooterButtons = ({ title, icon, onClick }: Props) => {
	return (
		<div
			className="d-flex align-items-center justify-content-center w-100 mt-3"
			onClick={onClick}
		>
			<div
				style={{
					width: "80%",
				}}
				className={
					"d-flex align-items-center justify-content-center p-2 rounded-3 footer-btn"
				}
			>
				<div className="mr-3">{icon}</div>
				<span>{title}</span>
			</div>
		</div>
	);
};

export default FooterButtons;
