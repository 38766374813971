import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Image, Table } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupee, faPlus } from "@fortawesome/free-solid-svg-icons";

import { ROUTES } from "../../navigators/Routes";

import { images } from "../../assets";

import VerticalInput from "../../components/VerticalInput/VerticalInput";
import Dropdown1 from "../../components/Dropdown1/Dropdown1";
import SearchInput from "../../components/SearchInput/SearchInput";
import PaymentDescription from "../../components/PaymentDescription/PaymentDescripition";
import DeletePopUp from "../../components/DeletePopUp/DeletePopUp";

const NewSalesOrder = () => {
  const navigate = useNavigate();
  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [eMail, setEMail] = useState<string>();
  const [contactNo, setContactNo] = useState<string>();

  const [typeOfSale, setTypeOfSale] = useState("-");
  const [expectedDDate, setExpectedDDate] = useState();
  const [orderStatus, setOrderStatus] = useState("Pending");

  const [qty, setQty] = useState();

  const [billNo, setBillNo] = useState();
  const [salesOrderNo, setSalesOrderNo] = useState("");
  const [billDate, setBillDate] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("Due On Receipt");
  const [dueDate, setDueDate] = useState("");
  const [paymentMode, setPaymentMode] = useState("Cash");
  const [paymentStatus, setPaymentStatus] = useState("Select Payment Status");
  const [referenceNO, setReferenceNO] = useState("");

  const [showDelete, setShowDelete] = useState(false);

  const handleCancel = () => {
    navigate(ROUTES.ORDERS);
  };

  const handleSave = () => {
    navigate(ROUTES.All_ORDERS);
  };

  return (
    <div className="p-3 flex flex-column gap-4">
      <h4 className="fw-bold">New Sales Order</h4>

      {/* Customer Info */}
      <div className="mt-3">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h6 className="fw-semibold text-lg">Customer Information</h6>
          <a href="#/" className="text-xs fw-semibold text-decoration-none">
            Add new Customer?
          </a>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="Contact Number"
              value={contactNo}
              handleChange={setContactNo}
              placeHolder="+91 9192111023"
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="Customer ID"
              value={customerId}
              handleChange={setCustomerId}
              placeHolder="12345678"
              inputClass="bg-light"
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="Name"
              value={customerName}
              handleChange={setCustomerName}
              placeHolder="Customer Name"
              readOnly={true}
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="E-Mail"
              value={eMail}
              handleChange={setEMail}
              placeHolder="abc@gmail.com"
            />
          </div>
        </div>
      </div>

      {/* Sales Type */}
      <div>
        <h6 className="fw-semibold mb-3 text-lg">Sales Type</h6>
        <div className="flex gap-3">
          <div className="row gap-2 w-2">
            <div className="text-sm text-secondary fw-bold">Type of Sale</div>
            <Dropdown1
              title={typeOfSale}
              titles={["In Store", "Online"]}
              placeholderText=""
              onSelect={setTypeOfSale}
            />
          </div>
          <div className="">
            <VerticalInput
              label="Expected Delivery"
              value={expectedDDate}
              handleChange={setExpectedDDate}
              placeHolder="30/07/2024"
            />
          </div>
          <div className="row gap-2 w-2">
            <div className="text-sm text-secondary fw-bold">Order Status</div>
            <Dropdown1
              title={orderStatus}
              titles={[
                "Pending",
                "On-Process",
                "Out For Delivery",
                "Order Delivered",
              ]}
              placeholderText=""
              onSelect={setOrderStatus}
            />
          </div>
        </div>
      </div>

      {/* Product Information */}
      <div>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h6 className="fw-semibold text-lg">Product Information</h6>
          <a href="#/" className="text-xs fw-semibold text-decoration-none">
            Add new Product?
          </a>
        </div>
        <div className="flex gap-3">
          <SearchInput placeholder="Search for existing product" />
          <div>
            <input
              type="number"
              value={qty}
              onChange={(e: any) => {
                setQty(e.target.value);
              }}
              className="form-control text-sm"
              placeholder={"Qty"}
            />
          </div>
          <Button variant="dark" className="rounded-3 text-sm">
            Add to Bill <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
        <Table className="p-table transparent-table mt-3">
          <thead className={`bg-light text-sm rounded-t-3`}>
            <tr>
              <td rowSpan={3}>Item Details</td>
              <td>Sales Order Quantity</td>
              <td>Cost Price (in ₹)</td>
              <td>Amount (in ₹)</td>
            </tr>
          </thead>
          <tbody>
            <tr className="border-bottom table-tr">
              <td className="w-5">
                <div className="d-flex align-items-center">
                  <Image
                    src={images.Rice}
                    height={45}
                    width={45}
                    className="border rounded-3 border-2"
                    style={{
                      marginRight: "10px",
                    }}
                  />
                  <div>Organic Sonamasuri Brown Rice</div>
                </div>
              </td>
              <td>
                <input
                  type="number"
                  className="form-control w-50"
                  value={"2"}
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control w-50"
                  value={350}
                />
              </td>
              <td className="font-bold">700</td>
            </tr>
          </tbody>
        </Table>
      </div>

      {/* General Info */}
      <div className="p-3 bg-light rounded-4">
        <h6 className="fw-semibold mb-4 text-lg">General Information</h6>
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="Bill Number#*"
              value={billNo}
              handleChange={setBillNo}
              placeHolder="SO - 0007"
              readOnly={true}
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="Sales Order Number"
              value={salesOrderNo}
              handleChange={setSalesOrderNo}
              placeHolder="SO - 0007"
              readOnly={true}
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="Bill Date*"
              value={billDate}
              handleChange={setBillDate}
              placeHolder="30/07/2024"
            />
          </div>
          <div className="gap-2 col-md-6 col-lg-3">
            <div className="text-sm text-secondary fw-bold mb-2">
              Payment Terms*
            </div>
            <Dropdown1
              title={paymentTerms}
              titles={[
                "Due On Receipt",
                "Net 15",
                "Net 30",
                "Net 45",
                "Net 60",
              ]}
              placeholderText=""
              onSelect={setPaymentTerms}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="Due Date*"
              value={dueDate}
              handleChange={setDueDate}
              placeHolder="30/07/2024"
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="text-sm text-secondary fw-bold mb-2">
              Payment Mode*
            </div>
            <Dropdown1
              title={paymentMode}
              titles={[
                "UPI",
                "Debit/Credit Card",
                "Internet Banking",
                "Cash",
                "Credit",
              ]}
              placeholderText=""
              onSelect={setPaymentMode}
            />
          </div>
          <div className="gap-2 col-md-6 col-lg-3">
            <div className="text-sm text-secondary fw-bold mb-2">
              Payment Status*
            </div>
            <Dropdown1
              title={paymentStatus}
              titles={["Pending", "Received"]}
              placeholderText=""
              onSelect={setPaymentStatus}
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <VerticalInput
              label="Reference Number"
              value={referenceNO}
              handleChange={setReferenceNO}
              placeHolder="210111121"
            />
          </div>
        </div>
      </div>

      {/* Payment terms */}
      <div className="flex gap-3 align-items-center mt-4">
        <div className="text-sm text-secondary w-50">
          <span className="fw-bold mr-2">Note:</span>All products ordered must
          meet the specified specifications and quality requirements as detailed
          in purchase order.
        </div>
        <div className="w-50">
          <PaymentDescription />
        </div>
      </div>

      {/* Bottom Bar*/}
      <div className="p-2 b-bar px-3 bg-white rounded-3 shadow flex justify-content-between align-items-center text-base">
        <div className="flex gap-3 fw-bold">
          <div className="text-secondary">Total</div>
          <div>0</div>
        </div>
        <div className="flex gap-4">
          <Button
            variant=""
            className="rounded-3 cancel px-4 text-sm"
            onClick={() => {
              setShowDelete(true);
            }}
          >
            Cancel
          </Button>
          <Button variant="" className="rounded-3 draft text-sm">
            Save as Draft
          </Button>
          <Button
            variant=""
            className="rounded-3 hover:bg-primary text-sm bg-dark text-white"
            onClick={handleSave}
          >
            Save & Record Payment <FontAwesomeIcon icon={faIndianRupee} />
          </Button>
        </div>
      </div>

      <DeletePopUp
        btnName={"Save as Draft"}
        show={showDelete}
        body={
          "Any unsaved changes will be lost. Do you want to save them in drafts?"
        }
        handleClose={handleCancel}
      />
    </div>
  );
};

export default NewSalesOrder;
