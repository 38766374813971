import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

import { Form, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";

function DateRangePicker() {
  const [startDate, setStartDate] = useState(new Date("2024-01-01"));
  const [endDate, setEndDate] = useState(new Date("2024-12-31"));

  return (
    <InputGroup className="flex w-5 gap-2 align-items-center text-secondary mr-2">
      <Form.Label className="pt-2">Show data from</Form.Label>
      <div className="flex gap-2 border-2 border-secondary px-3 align-items-center rounded-3 w-8">
        <DatePicker
          selected={startDate}
          onChange={(date: any) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yyyy"
          popperPlacement="bottom-start"
          customInput={<Form.Control />}
          className="border-none shadow-none"
        />
        <span>⇌</span>
        <DatePicker
          selected={endDate}
          onChange={(date: any) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat="dd/MM/yyyy"
          popperPlacement="bottom-start"
          customInput={<Form.Control />}
          className="border-none shadow-none"
        />
        <FontAwesomeIcon icon={faCalendarDays} />
      </div>
    </InputGroup>
  );
}

export default DateRangePicker;
