import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserId } from "../../utils/helper";
import { ROUTES } from "../../navigators/Routes";

type Props = {};

const Dashboard = (props: Props) => {
	const navigate = useNavigate();

	useEffect(() => {
		let id;
		let a = async () => {
			id = await getUserId();
			if (!id) {
				navigate(ROUTES.LOGIN, { replace: true });
			}
		};
		a();
	}, [navigate]);

	return (
		<div className="h-100 d-flex align-items-center justify-content-center">
			<h1>This Page is under construction</h1>
		</div>
	);
};

export default Dashboard;
