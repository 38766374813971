import { Table } from "react-bootstrap";
const OrdersTable = () => {
  return (
    <Table className="transparent-table b-table">
      <thead className={`bg-light`}>
        <tr>
          <th>Amount</th>
          <th>
            Date/<span className="fw-normal">Updated Time</span>
          </th>
          <th>Bill No #</th>
          <th>Vendor Name</th>
          <th>Quantity</th>
          <th className="text-center">Order Status</th>
          <th className="text-center">Payment Status</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 4 }).map((_, index) => (
          <tr key={index}>
            <td className="text-lg fw-bold">₹1,200</td>
            <td>
              <div className="flex flex-column align-items-center">
                09 Aug 2024
                <div className="w-6 text-xs text-secondary">
                  10 Aug 2024, 03:50 PM
                </div>
              </div>
            </td>
            <td>
              <div className="flex flex-column align-items-center">
                1542100011
                <div className="text-xs text-secondary w-5">PO No : 011202</div>
              </div>
            </td>
            <td className="text-center">24 Mantra Organic...</td>
            <td>
              <div className="flex flex-column align-items-center">
                20
                <div className="text-xs text-secondary w-5">
                  Cost Price : ₹160/unit
                </div>
              </div>
            </td>
            <td>
              <div className="flex flex-column align-items-center text-xs text-center text-secondary">
                <div className="onProcess p-1 fw-bold text-sm rounded-3 w-full">
                  On Process
                </div>
                <div className="w-5">Delivery Date : 12 Nov 2024</div>
              </div>
            </td>
            <td>
              <div className="flex flex-column align-items-center text-xs text-center text-secondary">
                <div className="pending p-1 fw-bold text-sm rounded-3 w-full">
                  Pending
                </div>
                <div className="w-6">Due Date : 30 Nov 2024</div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default OrdersTable;
