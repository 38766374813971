import { useState } from "react";

import Dropdown1 from "../Dropdown1/Dropdown1";
const PaymentDescription = () => {
  const [discount, setDiscount] = useState("%");
  return (
    <div className="d-flex flex-column gap-3 text-sm p-3 px-4 bg-light rounded-3">
      <div className="d-flex justify-content-between">
        <span className="detail-text fw-bold">
          Sub Total <span className="text-xs fw-normal">(inclusive GST)</span>
        </span>
        <span className="detail-amount">₹1,500.00</span>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <span className="detail-text fw-bold">
          GST <span className="text-xs fw-normal">(Goods & Service Tax)</span>
        </span>
        <div className="d-flex justify-content-between align-items-center w-6">
          <div className="w-7 flex align-items-center">
            <input
              type="number"
              value={5}
              className="p-2 rounded-start w-100 border outline-none text-center"
            />
            <span className="border p-2 px-3 rounded-end">%</span>
          </div>
          <span className="ms-2 detail-amount">₹75.00</span>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <span className="detail-text fw-bold">Discount</span>
        <div className="d-flex justify-content-between align-items-center w-6">
          <div className="w-7 flex align-items-center">
            <input
              type="number"
              value={2}
              className="p-2 rounded w-100 border outline-none text-center"
            />
            <div className="py-2">
              <Dropdown1
                title={discount}
                titles={["%", "₹"]}
                onSelect={setDiscount}
                placeholderText=""
              />
            </div>
          </div>
          <span className="ms-2 detail-amount">(₹30.00)</span>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <span className="detail-text fw-bold">Adjustments</span>
        <div className="d-flex justify-content-between align-items-center w-6">
          <div className="w-7 flex align-items-center">
            <input
              type="number"
              value={5}
              className="p-2 rounded-start w-100 border outline-none text-center"
            />
            <span className="border p-2 px-3 rounded-end">₹</span>
          </div>
          <span className="ms-2 detail-amount">(₹75.00)</span>
        </div>
      </div>
      <div className="d-flex fw-bold justify-content-between text-base border-top pt-3">
        <span>Total Amount</span>
        <span>₹1,395.00</span>
      </div>
    </div>
  );
};

export default PaymentDescription;
