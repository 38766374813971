import ApiService from "../services/ApiService";
import { END_POINTS } from "../utils/ApiEndpoints";

const BASE_API_URL = ApiService.getApiUrl();

export const addVendor = (payload: any) => {
  const URL = BASE_API_URL + END_POINTS.ADD_VENDOR;
  return ApiService.callPostApi(URL, payload);
};

export const addContactDetails = (payload: any) => {
  const URL = BASE_API_URL + END_POINTS.ADD_CONTACT_DETAILS;
  return ApiService.callPostApi(URL, payload);
};

export const addBankDetails = (payload: any) => {
  const URL = BASE_API_URL + END_POINTS.ADD_BANK_DETAILS;
  return ApiService.callPostApi(URL, payload);
};

export const updateVendor = (payload: any, id: number) => {
  const URL = BASE_API_URL + END_POINTS.UPDATE_VENDOR + id;
  return ApiService.callPutApi(URL, payload);
};

export const updateContactDetails = (payload: any, id: number | undefined) => {
  const URL = BASE_API_URL + END_POINTS.UPDATE_CONTACT + id;
  return ApiService.callPutApi(URL, payload);
};

export const getVendors = async () => {
  const URL = BASE_API_URL + END_POINTS.GET_VENDORS;
  return ApiService.callGetApi(URL);
};

export const getSingleVendor = async (id: number) => {
  const URL = BASE_API_URL + END_POINTS.GET_SINGLE_VENDOR + id;
  return ApiService.callGetApi(URL);
};
