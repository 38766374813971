interface VerticalInputProps {
  label?: string;
  value: string | undefined;
  handleChange?: any;
  placeHolder?: string;
  type?: string;
  inputClass?: string;
  readOnly?: boolean | false;
}

const VerticalInput = (props: VerticalInputProps) => {
  const {
    label,
    value,
    handleChange,
    placeHolder,
    type,
    inputClass,
    readOnly,
  } = props;
  return (
    <div className="text-sm">
      <label className="form-label fw-semibold text-muted">{label}</label>
      <input
        type={type}
        value={value}
        onChange={(e: any) => {
          handleChange(e.target.value);
        }}
        className={`form-control text-sm ${inputClass}`}
        placeholder={placeHolder}
        readOnly={readOnly}
      />
    </div>
  );
};

export default VerticalInput;
