import { Dispatch, SetStateAction } from "react";
import "./style.css";
interface BoxInputProps {
  label: string;
  placeHolder: string;
  handleChange:
    | Dispatch<SetStateAction<string | undefined>>
    | Dispatch<SetStateAction<string>>;
  value: string | undefined;
  readOnly?: boolean | false;
}

const BoxInput = (props: BoxInputProps) => {
  const { label, placeHolder, handleChange, value, readOnly } = props;
  return (
    <div className="flex flex-column rounded-3 p-2 justify-content-between border box">
      <span className="fw-semibold text-sm text-secondary">{label}</span>
      <input
        type="text"
        placeholder={placeHolder}
        onChange={(e: any) => {
          handleChange(e.target.value);
        }}
        value={value}
        className="outline-none border-none mb-2"
        readOnly={readOnly}
      />
    </div>
  );
};

export default BoxInput;
