import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";

// Pages
import Error from "../pages/Error";
import Main from "../pages/Main";
import Login from "../pages/Login";

// Utils
import { isUserAuthenticated } from "../utils/helper";

// Constants
import { ROUTES } from "./Routes";

// Pages
import Register from "../pages/Register";
import Products from "../pages/Products";
import Categories from "../pages/Categories";
import Brands from "../pages/Brands";
import AddEditProduct from "../pages/AddEditProduct";
import Adjustments from "../pages/Adjustments/Adjustments";
import AddAdjustments from "../pages/AddAdjustments/AddAdjustments";
import BrandsVendor from "../pages/Brands(vendor)/BrandsVendor";
import VendorDetails from "../pages/VendorDetails/VendorDetails";
import PurchaseOrders from "../pages/PurchaseOrders/PurchaseOrders";
import NewPurchaseOrder from "../pages/NewPurchaseOrder/NewPurchaseOrder";
import SendMail from "../pages/NewPurchaseOrder/SendMail";
import EditPurchaseOrder from "../pages/NewPurchaseOrder/EditPurchaseOrder";
import Bills from "../pages/Bills/Bills";
import EncryptionDecryption from "../pages/EncryptionDecryption/EncryptionDecryption";
import AllPurchaseOrders from "../pages/AllPurchaseOrders/AllPurchaseOrders";
import Profile from "../pages/Profile/Profile";
import Dashboard from "../pages/Dashboard";
import Customer from "../pages/Customers/Customer";
import NewCustomer from "../pages/NewCustomer/NewCustomer";
import AllCustomers from "../pages/AllCustomers/AllCustomers";
import SalesOrders from "../pages/SalesOrder/SalesOrders";
import NewSalesOrder from "../pages/NewSalesOrder/NewSalesOrder";
import AllSalesOrders from "../pages/AllSalesOrders/AllSalesOrders";

const router = createBrowserRouter([
  {
    path: ROUTES.MAIN,
    async loader() {
      try {
        // Check if the user is authenticated asynchronously
        const isAuthenticated = await isUserAuthenticated(); // Implement this function to check authentication

        // If user is authenticated, navigate to the main screen
        if (isAuthenticated) {
          return { element: <Main /> }; // Assuming MainScreen is the component for the main screen
        }

        // If user is not authenticated, navigate to the login route
        return redirect(ROUTES.LOGIN); // Redirect to the login route
      } catch (error) {
        // Handle errors if any
        console.error("Error during authentication:", error);
        return { element: <Error /> }; // Render an error screen
      }
    },
    element: <Main />, // Assuming MainScreen is the component for the main screen
    errorElement: <Error />, // Define an error element if needed
    children: [
      {
        path: ROUTES.PRODUCTS,
        element: <Products />,
      },
      {
        path: ROUTES.ADJUSTMENTS,
        element: <Adjustments />,
      },
      {
        path: ROUTES.CATEGORIES,
        element: <Categories />,
      },
      {
        path: ROUTES.BRANDS,
        element: <Brands />,
      },
      {
        path: ROUTES.ADD_EDIT_PRODUCT,
        element: <AddEditProduct />,
      },
      {
        path: ROUTES.ADD_ADJUSTMENTS,
        element: <AddAdjustments />,
      },
      {
        path: ROUTES.VENDOR,
        element: <BrandsVendor />,
      },
      {
        path: ROUTES.VENDOR_DETAILS,
        element: <VendorDetails />,
      },
      {
        path: ROUTES.VENDOR_DETAILS_ADD_PRODUCT,
        element: <AddEditProduct />,
      },
      {
        path: ROUTES.PURCHASE_ORDERS,
        element: <PurchaseOrders />,
      },
      {
        path: ROUTES.NEW_PURCHASE_ORDERS,
        element: <NewPurchaseOrder />,
      },
      {
        path: ROUTES.SENDMAIL,
        element: <SendMail />,
      },
      {
        path: ROUTES.ALL_PURCHASE_ORDERS,
        element: <AllPurchaseOrders />,
      },
      {
        path: ROUTES.EDIT_PURCHASE_ORDER,
        element: <EditPurchaseOrder />,
      },
      {
        path: ROUTES.BILLS,
        element: <Bills />,
      },
      {
        path: ROUTES.CUSTOMERS,
        element: <Customer />,
      },
      {
        path: ROUTES.ADD_CUSTOMER,
        element: <NewCustomer />,
      },
      {
        path: ROUTES.ALL_CUSTOMERS,
        element: <AllCustomers />,
      },
      {
        path: ROUTES.ORDERS,
        element: <SalesOrders />,
      },
      {
        path: ROUTES.NEW_ORDER,
        element: <NewSalesOrder />,
      },
      {
        path: ROUTES.All_ORDERS,
        element: <AllSalesOrders />,
      },
      {
        path: ROUTES.PROFILE,
        element: <Profile />,
      },
      {
        path: ROUTES.DISCOVER_BRANDS,
        element: <Dashboard />,
      },
    ],
  },
  {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTES.REGISTER,
    element: <Register />,
  },
  {
    path: ROUTES.ENC_DEC,
    element: <EncryptionDecryption />,
  },
  {
    path: "*",
    element: <Error />, // Render an error screen for any other route
  },
]);

type Props = {};

const Navigator = (props: Props) => {
  return <RouterProvider router={router} />;
};

export default Navigator;
