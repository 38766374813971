"use client";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faBank } from "@fortawesome/free-solid-svg-icons";

import { toast } from "../../utils/helper";

import Modal from "../VendorModal/Modal";
import BoxInput from "../BoxInput/BoxInput";
import {
  addBankDetails,
  addContactDetails,
  updateContactDetails,
  updateVendor,
} from "../../controller/VendorController";

import "./style.css";

interface VendorGeneralInfoProps {
  vendorDetails: VendorDataTypes;
}

interface VendorDataTypes {
  id: number;
  name: string;
  address: string;
  email: string;
  logo: string;
  paymentTerms: string;
  currency: string;
  portalStatus: boolean;
  website: string;
  bankDetails: Array<any>;
  contacts: Array<any>;
  products: Array<any>;
  createdBy: object;
  logoUrl: string;
}

interface BankDetailsTypes {
  id: number;
  name: string;
  accountHolderName: string;
  accountNumber: string;
  ifsc: string;
}

interface ContactDetailsTypes {
  id?: number;
  name: string;
  department?: string; // Optional
  email: string;
  contactNumber: string;
  primary?: boolean; // Optional
  vendorId: string;
}

const VendorGeneralInfo = (props: VendorGeneralInfoProps) => {
  const { vendorDetails } = props;

  const [buttonLoading, setButtonLoading] = useState(false);

  const [portalStatus, setPortalStatus] = useState(false);

  // const [AddAddressModal, setAddAddressModal] = useState(false);
  const [EditAddressModal, setEditAddressModal] = useState(false);

  const [AddBankModal, setAddBankModal] = useState(false);

  const [AddContactModal, setAddContactModal] = useState(false);
  const [EditContactModal, setEditContactModal] = useState(false);

  // Billing Address
  const [address, setAddress] = useState("");

  // Bank details
  const [bankName, setBankName] = useState("");
  const [holderName, setHolderName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [confirmAccountNo, setConfirmAccountNo] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [bankDetails, setBankDetails] = useState<Array<BankDetailsTypes>>([]);

  // Contact Details
  const [editContactId, setEditContactId] = useState<number>();
  const [department, setDepartment] = useState<string | undefined>("");
  const [contactName, setContactName] = useState("");
  const [eMail, setEMail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [primary, setPrimary] = useState<boolean | undefined>(false);
  const [otherContacts, setOtherContacts] = useState<
    Array<ContactDetailsTypes>
  >([]);
  const [primaryContact, setPrimaryContact] = useState<ContactDetailsTypes>();

  useEffect(() => {
    if (vendorDetails) {
      setAddress(vendorDetails.address);
      setPortalStatus(vendorDetails.portalStatus);
      setBankDetails(vendorDetails.bankDetails);
      setPrimaryContact(
        vendorDetails.contacts.find((contact) => contact.primary)
      );
      setOtherContacts(
        vendorDetails.contacts.filter((contact) => !contact.primary)
      );
    }
    if (bankDetails.length === 0) {
      setBankDetails([
        {
          id: 0,
          name: "-",
          accountHolderName: "-",
          accountNumber: "-",
          ifsc: "-",
        },
      ]);
    }
  }, [vendorDetails,bankDetails]);

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleClose = () => {
    // setAddAddressModal(false);
    setEditAddressModal(false);
    setAddBankModal(false);
    setAddContactModal(false);
    setEditContactModal(false);
    handleRefresh();
  };

  const handleToggle = () => {
    setPortalStatus(!portalStatus);
  };

  const handleAddressSave = async () => {
    try {
      setButtonLoading(true);
      const preparedData = {
        address: address,
      };
      const response = await updateVendor(preparedData, vendorDetails.id);
      if (response) {
        toast.success("Address Updated Successfully");
        setButtonLoading(false);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setButtonLoading(false);
      handleClose();
    }
  };

  const handleBankDetailsSave = async () => {
    if (!bankName && !accountNo && !holderName && !ifsc && !confirmAccountNo) {
      toast.error(
        "Fill All Required Fields (Bank Name,Account Number,IFSC code,Confirm Account Number)"
      );
    } else if (accountNo !== confirmAccountNo) {
      toast.error("Confirm Account Number Must Be Same as Account Number");
    } else {
      try {
        setButtonLoading(true);
        const preparedData = {
          name: bankName,
          accountHolderName: holderName,
          accountNumber: accountNo,
          ifsc: ifsc,
          vendorId: vendorDetails.id.toString(),
        };
        const response = await addBankDetails(preparedData);
        if (response) {
          setButtonLoading(false);
          handleClose();
          toast.success("Bank Account Added Successfully");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setButtonLoading(false);
        setBankName("");
        setHolderName("");
        setAccountNo("");
        setConfirmAccountNo("");
        setIfsc("");
        handleClose();
      }
    }
  };

  const handleContactSave = async () => {
    if (!contactName && !eMail && !contactNo) {
      toast.error(
        "Fill All Required Fields (Contact Name,Email,Contact Number)"
      );
    } else {
      try {
        setButtonLoading(true);
        const preparedData = {
          name: contactName,
          department: department,
          email: eMail,
          contactNumber: contactNo,
          primary: primary,
          vendorId: vendorDetails.id.toString(),
        };
        const response = await addContactDetails(preparedData);
        if (response) {
          toast.success("Contact Added Successfully");
          setButtonLoading(false);
          handleClose();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setButtonLoading(false);
        setDepartment("");
        setContactName("");
        setEMail("");
        setContactNo("");
        setPrimary(false);
        handleClose();
      }
    }
  };

  const handleEditContact = async () => {
    try {
      setButtonLoading(true);
      const preparedData = {
        name: contactName,
        department: department,
        email: eMail,
        contactNumber: contactNo,
        primary: primary,
      };
      const response = await updateContactDetails(preparedData, editContactId);
      if (response) {
        toast.success("Contact Updated Successfully");
        setButtonLoading(false);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setButtonLoading(false);
      setDepartment("");
      setContactName("");
      setEMail("");
      setContactNo("");
      setPrimary(false);
      handleClose();
    }
  };

  return (
    <div className="mt-3 flex gap-4">
      {/* Left side */}
      <div className="w-50 flex flex-column gap-4">
        {/* Address */}
        <div className="flex flex-column gap-3">
          {/* Title and Add button */}
          <div className="flex justify-content-between">
            <span className="fw-semibold text-lg">Address</span>
            {/* <button
              className="bg-transparent border-none flex text-sm gap-2 align-items-center fw-semibold"
              style={{ color: "#005D58" }}
              onClick={() => {
                setAddAddressModal(true);
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faPlus} /> Add Address
            </button> */}
          </div>
          <div className="flex flex-column rounded-3 p-2 gap-3 border">
            <div className="flex justify-content-between">
              <span className="fw-semibold text-sm text-secondary">
                Billing Address
              </span>
              <FontAwesomeIcon
                icon={faPen}
                size="sm"
                onClick={() => {
                  setEditAddressModal(true);
                }}
              />
            </div>
            <span>{address}</span>
          </div>
        </div>
        {/* Other Details */}
        <div className="flex flex-column gap-3">
          <span className="fw-semibold text-lg">Other Details</span>
          <div className="flex justify-content-between">
            <div className="flex flex-column p-2 border rounded-3 w-25">
              <span className="text-secondary text-sm">Payment Terms</span>
              <span className="text-base">
                {vendorDetails ? vendorDetails.paymentTerms : ""}
              </span>
            </div>
            <div className="flex flex-column p-2 border rounded-3 w-25">
              <span className="text-secondary text-sm">Currency</span>
              <span className="text-base">
                {vendorDetails ? vendorDetails.currency : ""}
              </span>
            </div>
            <div className="flex flex-column p-2 border rounded-3 w-25">
              <span className="text-secondary text-sm">Portal Status</span>
              <div className="flex justify-content-between text-base">
                Active{" "}
                <div
                  className={`toggle-switch ${portalStatus ? "on" : "off"}`}
                  onClick={handleToggle}
                >
                  <div className="toggle-handle" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bank Account Details */}
        <div className="flex flex-column gap-3">
          <div className="flex justify-content-between">
            <span className="fw-semibold text-lg">Bank Account Details</span>
            <button
              className="bg-transparent border-none flex text-sm gap-2 align-items-center fw-semibold"
              style={{ color: "#005D58" }}
              onClick={() => {
                setAddBankModal(true);
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faPlus} /> Add Bank Account
            </button>
          </div>
          <div className="flex flex-column gap-3">
            {bankDetails.map((item: any) => {
              return (
                <div
                  className="flex flex-column gap-3 border border-2 rounded-3 px-3 py-3"
                  style={{
                    width: "60%",
                    color: "#6C757D",
                    fontSize: "12.69px",
                  }}
                  key={item.id}
                >
                  <div className="flex justify-content-between gap-2">
                    <span
                      className="flex gap-1 align-items-center"
                      style={{ width: "42.5%" }}
                    >
                      <FontAwesomeIcon icon={faBank} />
                      Bank Name
                    </span>
                    <span style={{ width: "5%" }}>:</span>
                    <span style={{ width: "42.5%" }}>{item.name}</span>
                  </div>
                  <div className="flex justify-content-between gap-2">
                    <span
                      className="flex gap-1 align-items-center"
                      style={{ width: "42.5%" }}
                    >
                      Account Holder Name
                    </span>
                    <span style={{ width: "5%" }}>:</span>
                    <span style={{ width: "42.5%" }}>
                      {item.accountHolderName}
                    </span>
                  </div>
                  <div className="flex justify-content-between gap-2">
                    <span
                      className="flex gap-1 align-items-center"
                      style={{ width: "42.5%" }}
                    >
                      Account Number
                    </span>
                    <span style={{ width: "5%" }}>:</span>
                    <span style={{ width: "42.5%" }}>{item.accountNumber}</span>
                  </div>
                  <div className="flex justify-content-between gap-2">
                    <span
                      className="flex gap-1 align-items-center"
                      style={{ width: "42.5%" }}
                    >
                      IFSC Code
                    </span>
                    <span style={{ width: "5%" }}>:</span>
                    <span style={{ width: "42.5%" }}>{item.ifsc}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Right side */}
      <div className="w-50 flex flex-column gap-4">
        {/* Contact Person */}
        <div className="flex flex-column gap-3">
          {/* Title and Add button */}
          <div className="flex justify-content-between">
            <span className="fw-semibold text-lg">Contact Person</span>
            <button
              className="bg-transparent border-none flex text-sm gap-2 align-items-center fw-semibold"
              style={{ color: "#005D58" }}
              onClick={() => {
                setAddContactModal(true);
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faPlus} /> Add Contact
            </button>
          </div>
          {/* Details Container */}
          <div className="py-2 px-3 rounded-3 flex flex-column gap-3 bg-eee">
            <div className="flex justify-content-between">
              <div
                className="flex flex-column p-2 w-100 rounded-3"
                style={{ width: "30%" }}
              >
                <span
                  style={{
                    color: "#6C757D",
                    fontSize: "12.69px",
                    fontWeight: "600",
                  }}
                >
                  Contact Name
                </span>
                <span className="text-lg fw-semibold">
                  {primaryContact?.name || "N/A"}
                </span>
              </div>
              <div>
                <button className="text-sm outline-none border-none bg-white rounded-3 px-2 py-1">
                  Primary
                </button>
              </div>
            </div>
            <div className="flex justify-content-between">
              <div
                className="flex flex-column p-2 w-100 rounded-3"
                style={{ width: "30%" }}
              >
                <span
                  style={{
                    color: "#6C757D",
                    fontSize: "12.69px",
                    fontWeight: "600",
                  }}
                >
                  Department
                </span>
                <span>{primaryContact?.department || "N/A"}</span>
              </div>
            </div>
            <div className="flex justify-content-between">
              <div
                className="flex flex-column p-2 w-100 rounded-3"
                style={{ width: "30%" }}
              >
                <span
                  style={{
                    color: "#6C757D",
                    fontSize: "12.69px",
                    fontWeight: "600",
                  }}
                >
                  EMAIL
                </span>
                <span>{primaryContact?.email || "N/A"}</span>
              </div>
            </div>
            <div className="flex justify-content-between">
              <div
                className="flex flex-column p-2 w-100 rounded-3"
                style={{ width: "30%" }}
              >
                <span
                  style={{
                    color: "#6C757D",
                    fontSize: "12.69px",
                    fontWeight: "600",
                  }}
                >
                  CONTACT
                </span>
                <span>{primaryContact?.contactNumber || "N/A"}</span>
              </div>
            </div>
            {primaryContact && (
              <div className="flex justify-content-between mt-4">
                <FontAwesomeIcon
                  icon={faPen}
                  className="ml-2"
                  onClick={() => {
                    setEditContactId(primaryContact.id);
                    setContactName(primaryContact.name);
                    setContactNo(primaryContact.contactNumber);
                    setDepartment(primaryContact.department);
                    setPrimary(primaryContact.primary);
                    setEMail(primaryContact.email);
                    setEditContactModal(true);
                  }}
                />
                <button className="bg-black border-none rounded-3 py-1 px-2 text-sm text-white">
                  Send Email
                </button>
              </div>
            )}
          </div>
        </div>
        {/* Other Contact Person */}
        {otherContacts.length > 0 && (
          <div className="flex flex-column gap-3">
            {/* Title */}
            <span className="fw-semibold text-lg">Other Contact Person</span>
            {/* Details Container */}
            {otherContacts.map((item: any) => {
              return (
                <div
                  className="py-2 px-3 rounded-3 flex flex-column gap-3 bg-eee"
                  key={item.id}
                >
                  <div className="flex justify-content-between">
                    <div
                      className="flex flex-column p-2 w-100 rounded-3"
                      style={{ width: "30%" }}
                    >
                      <span
                        style={{
                          color: "#6C757D",
                          fontSize: "12.69px",
                          fontWeight: "600",
                        }}
                      >
                        Contact Name
                      </span>
                      <span className="text-lg fw-semibold">{item.name}</span>
                    </div>
                    <div className="text-sm mt-2">
                      <FontAwesomeIcon
                        icon={faPen}
                        onClick={() => {
                          setEditContactId(item.id);
                          setContactName(item.name);
                          setContactNo(item.contactNumber);
                          setDepartment(item.department);
                          setPrimary(item.primary);
                          setEMail(item.email);
                          setEditContactModal(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex justify-content-between">
                    <div
                      className="flex flex-column p-2 w-100 rounded-3"
                      style={{ width: "30%" }}
                    >
                      <span
                        style={{
                          color: "#6C757D",
                          fontSize: "12.69px",
                          fontWeight: "600",
                        }}
                      >
                        EMAIL
                      </span>
                      <span>{item.email}</span>
                    </div>
                  </div>
                  <div className="flex justify-content-between">
                    <div
                      className="flex flex-column p-2 w-100 rounded-3"
                      style={{ width: "30%" }}
                    >
                      <span
                        style={{
                          color: "#6C757D",
                          fontSize: "12.69px",
                          fontWeight: "600",
                        }}
                      >
                        CONTACT
                      </span>
                      <span>{item.contactNumber}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* Modals for Edit and Add */}

      {/* Add Address Modal */}
      {/* <Modal
        title="Add Address"
        show={AddAddressModal}
        handleClose={handleClose}
        handleSave={handleAddressSave}
        SaveText={"Save"}
        loading={buttonLoading}
        size="lg"
        body={
          <BoxInput
            label="BILLING ADDRESS"
            placeHolder="Add billing address of vendor"
            handleChange={setAddress}
            value={address}
          />
        }
      /> */}
      {/* Edit Address Modal */}
      <Modal
        title="Edit Address"
        show={EditAddressModal}
        handleClose={handleClose}
        handleSave={handleAddressSave}
        SaveText={"Save"}
        loading={buttonLoading}
        size="lg"
        body={
          <BoxInput
            label="BILLING ADDRESS"
            placeHolder="Edit billing address of vendor"
            handleChange={setAddress}
            value={address}
          />
        }
      />
      {/* Add Bank Details */}
      <Modal
        title="Add Bank Details"
        show={AddBankModal}
        handleClose={handleClose}
        handleSave={handleBankDetailsSave}
        size="lg"
        loading={buttonLoading}
        SaveText={"Save"}
        body={
          <div className="flex gap-4 flex-column">
            <div className="flex gap-4">
              <div className="w-50">
                <BoxInput
                  label="Bank Name"
                  placeHolder="Add bank name"
                  handleChange={setBankName}
                  value={bankName}
                />
              </div>
              <div className="w-50">
                <BoxInput
                  label="Account Holder Name"
                  placeHolder="Add account holder name"
                  handleChange={setHolderName}
                  value={holderName}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="w-50">
                <BoxInput
                  label="Account Number"
                  placeHolder="Add account number"
                  handleChange={setAccountNo}
                  value={accountNo}
                />
              </div>
              <div className="w-50">
                <BoxInput
                  label="Confirm Account Number"
                  placeHolder="Add account number"
                  handleChange={setConfirmAccountNo}
                  value={confirmAccountNo}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="w-50">
                <BoxInput
                  label="IFSC Code"
                  placeHolder="Add IFSC Code"
                  handleChange={setIfsc}
                  value={ifsc}
                />
              </div>
              <div className="w-50"></div>
            </div>
          </div>
        }
      />
      {/* Add Contact Details */}
      <Modal
        title="Add Contact Details"
        show={AddContactModal}
        handleClose={handleClose}
        handleSave={handleContactSave}
        size="lg"
        SaveText={"Save"}
        body={
          <div className="flex gap-4 flex-column">
            <div className="flex gap-4">
              <div className="w-50">
                <BoxInput
                  label="Contact Name"
                  placeHolder="Add contact name"
                  handleChange={setContactName}
                  value={contactName}
                />
              </div>
              <div className="w-50">
                <BoxInput
                  label="Department"
                  placeHolder="Add department"
                  handleChange={setDepartment}
                  value={department}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="w-50">
                <BoxInput
                  label="E-Mail Address"
                  placeHolder="Add email address"
                  handleChange={setEMail}
                  value={eMail}
                />
              </div>
              <div className="w-50">
                <BoxInput
                  label="Contact Number"
                  placeHolder="Add contact number"
                  handleChange={setContactNo}
                  value={contactNo}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div
                className="flex rounded-3 w-50 p-2 align-items-center justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "50px" }}
              >
                <span className="fw-semibold text-sm text-secondary">
                  PRIMARY
                </span>
                <input
                  type="checkbox"
                  className="outline-none border-none"
                  checked={primary}
                  onChange={() => {
                    setPrimary(!primary);
                  }}
                />
              </div>
              <div className="w-50"></div>
            </div>
          </div>
        }
      />
      {/* Edit Contact Details */}
      <Modal
        title="Edit Contact Details"
        show={EditContactModal}
        handleClose={handleClose}
        handleSave={handleEditContact}
        size="lg"
        SaveText={"Save"}
        body={
          <div className="flex gap-4 flex-column">
            <div className="flex gap-4">
              <div className="w-50">
                <BoxInput
                  label="Contact Name"
                  placeHolder="Add contact name"
                  handleChange={setContactName}
                  value={contactName}
                />
              </div>
              <div className="w-50">
                <BoxInput
                  label="Department"
                  placeHolder="Add department"
                  handleChange={setDepartment}
                  value={department}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="w-50">
                <BoxInput
                  label="E-Mail Address"
                  placeHolder="Add email address"
                  handleChange={setEMail}
                  value={eMail}
                />
              </div>
              <div className="w-50">
                <BoxInput
                  label="Contact Number"
                  placeHolder="Add contact number"
                  handleChange={setContactNo}
                  value={contactNo}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div
                className="flex rounded-3 w-50 p-2 align-items-center justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "50px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "15px" }}
                >
                  PRIMARY
                </span>
                <input type="checkbox" className="outline-none border-none" />
              </div>
              <div className="w-50"></div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default VendorGeneralInfo;
