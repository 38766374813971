import React from "react";
import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import "./style.css";

const AllCustomerSidebar: React.FC = () => {
  return (
    <div className="py-3 shadow h-100">
      <div className="d-flex justify-content-between align-items-center mb-3 px-2">
        <h5 className="text-xl fw-bold">
          All Customers <FontAwesomeIcon icon={faChevronDown} />
        </h5>
        <Button variant="dark" className="rounded-3 text-sm">
          Add New <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </Button>
      </div>

      <div className="flex grey-hover gap-2 p-3 border-bottom">
        <div className="rounded d-flex justify-content-between">
          <div className="flex gap-2 w-full">
            <input type="checkbox" name="" id="" />
            <div className="flex flex-column gap-2">
              <div className="fw-bold">Arshad Qureshi</div>
              <div className="rounded-3 fw-bold text-xs flex gap-2 success p-1">
                RECEIVABLES <span>₹400.00</span>
              </div>
              <div className="text-sm text-secondary p-1 fw-bold flex justify-content-between">
                Payables <span>₹ 0.00</span>
              </div>
            </div>
          </div>
          <div className="flex flex-column justify-content-between align-items-end">
            <span className="fw-bold text-lg">54</span>
            <div className="text-secondary fw-bold text-right small-font">
              TOTAL PRODUCT PURCHASED TILL DATE
            </div>
          </div>
        </div>
      </div>
      <div className="flex grey-hover gap-2 p-3 border-bottom">
        <div className="rounded d-flex justify-content-between">
          <div className="flex gap-2 w-full">
            <input type="checkbox" name="" id="" />
            <div className="flex flex-column gap-2">
              <div className="fw-bold">Arshad Qureshi</div>
              <div className="rounded-3 fw-bold text-xs flex gap-2 success p-1">
                RECEIVABLES <span>₹400.00</span>
              </div>
              <div className="text-xs rounded-3 delay p-1 fw-bold flex justify-content-between">
                Payables <span>₹ 40.00</span>
              </div>
            </div>
          </div>
          <div className="flex flex-column justify-content-between align-items-end">
            <span className="fw-bold text-lg">54</span>
            <div className="text-secondary fw-bold text-right small-font">
              TOTAL PRODUCT PURCHASED TILL DATE
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCustomerSidebar;
