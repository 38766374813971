import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";

import {
  POHeader,
  DateRangePicker,
} from "../../components/PurchaseOrderComponents";
import NavTabs from "../../components/NavTabs/NavTabs";
import SearchInput from "../../components/SearchInput/SearchInput";
import { OrdersTable } from "../../components/SalesOrdersComponents";
import { ROUTES } from "../../navigators/Routes";

const SalesOrders = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("All Sales");

  const handleCreate = () => {
    navigate(ROUTES.NEW_ORDER);
  };
  return (
    <>
      <POHeader
        title="Sales Orders"
        buttonName={"Create Sales Order"}
        onButtonClick={handleCreate}
      />
      <div className="p-3 flex flex-column gap-4">
        <NavTabs
          tabs={[
            "All Sales",
            "In-Store",
            "Online",
            "Exchange",
            "Refund",
            "Draft",
          ]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className="flex justify-content-between">
          <SearchInput
            placeholder="Search by product name, vendor name, purchase order number...."
            width="40%"
          />
          <DateRangePicker />
        </div>
        <OrdersTable />
        <div className="d-flex gap-4">
          <div className="text-center p-1 border px-2 bg-primary-subtle  text-sm rounded-3">
            Monthly Sales Revenue <span className="fw-bold">₹11,180</span>
          </div>
          <div className="text-center p-1 border px-2 text-sm rounded-3 onProcess text-black">
            Average Order Value <span className="fw-bold">₹975</span>
          </div>
          <div className="text-center p-1 border px-2 bg-success-subtle text-sm rounded-3">
            Sales Growth Rate{" "}
            <span className="text-success fw-bold">
              {" "}
              40% <FontAwesomeIcon icon={faArrowTrendUp} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesOrders;
