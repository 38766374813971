import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "react-bootstrap";

import { ROUTES } from "../../navigators/Routes";

import NavTabs from "../../components/NavTabs/NavTabs";
import HorizontalInputField from "../../components/HorizontalInputField/HorizontalInputField";

const NewCustomer = () => {
  const navigate = useNavigate();

  const [active, setActive] = useState("Basic Details");

  const [cId, setCId] = useState("");
  const [cName, setCName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [eMail, setEMail] = useState("");
  const [dob, setDob] = useState("");
  const [billAddress, setBillAddress] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [discount, setDiscount] = useState("");
  const [creditLimit, setCreditLimit] = useState("");
  const [creditLimitTime, setCreditLimitTime] = useState("");

  const handleCancel = () => {
    navigate(ROUTES.CUSTOMERS);
  };

  const handleSave = () => {
    navigate(ROUTES.ALL_CUSTOMERS);
  };
  return (
    <div className="p-3">
      <h4 className="fw-bold mb-4">New Customer</h4>
      <NavTabs
        tabs={["Basic Details"]}
        activeTab={active}
        setActiveTab={setActive}
      />
      <div className="flex flex-column gap-3 mt-2">
        <div className="w-9">
          <HorizontalInputField
            label="Customer ID* :"
            value={cId}
            handleChange={setCId}
            placeHolder="1201920101"
            readonly
            inputClass="bg-body-secondary"
          />
        </div>
        <div className="w-9">
          <HorizontalInputField
            label="Customer Name* :"
            value={cName}
            handleChange={setCName}
            placeHolder="Add Customer Name"
          />
        </div>
        <div className="w-9">
          <HorizontalInputField
            label="Phone Number* :"
            value={phoneNo}
            handleChange={setPhoneNo}
            placeHolder="Add Phone Number"
          />
        </div>
        <div className="w-9">
          <HorizontalInputField
            label="Email Address* :"
            value={eMail}
            handleChange={setEMail}
            placeHolder="Add Your Store Email Address"
          />
        </div>
        <div className="w-9">
          <HorizontalInputField
            label="Date of Birth :"
            value={dob}
            handleChange={setDob}
            placeHolder="Add Customer DoB for Promotional Messages"
          />
        </div>
        <div className="w-9">
          <HorizontalInputField
            label="Billing Address :"
            value={billAddress}
            handleChange={setBillAddress}
            placeHolder="Add Your Retail Store Address"
          />
        </div>
        <div className="w-9">
          <HorizontalInputField
            label="Delivery Address* :"
            value={deliveryAddress}
            handleChange={setDeliveryAddress}
            placeHolder="Add Your Delivery Address"
          />
        </div>
        <div className="w-9 mt-5">
          <HorizontalInputField
            label="Discount (%) :"
            value={discount}
            handleChange={setDiscount}
            placeHolder="0"
          />
          <div className="text-secondary text-xs flex justify-content-between">
            <div className="w-4"></div>
            <div className="w-full">Set default discount to your customer</div>
          </div>
        </div>
        <div className="w-9">
          <HorizontalInputField
            label="Credit Limit :"
            value={creditLimit}
            handleChange={setCreditLimit}
            placeHolder="0"
          />
          <div className="text-secondary text-xs flex justify-content-between">
            <div className="w-4"></div>
            <div className="w-full">
              Warning will be shown when customer is exceeding the credit limit
              while creating invoice.
            </div>
          </div>
        </div>
        <div className="w-9">
          <HorizontalInputField
            label="Credit Limit Time :"
            value={creditLimitTime}
            handleChange={setCreditLimitTime}
          />
          <div className="text-secondary text-xs flex justify-content-between">
            <div className="w-4"></div>
            <div className="w-full">
              Warning will be shown when customer is exceeding the credit limit
              time while creating invoice.
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 bottom-0 flex justify-content-end gap-4">
        <Button
          className="bg-transparent text-sm rounded-3 border-danger text-danger px-3 w-1"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="dark"
          className="rounded-3 text-sm p-2 px-4"
          onClick={handleSave}
        >
          Save & Update
        </Button>
      </div>
    </div>
  );
};

export default NewCustomer;
