import { useNavigate } from "react-router-dom";

import { Image } from "react-bootstrap";

import { ROUTES } from "../../navigators/Routes";

import Button from "../Button";

interface ViewBrandVendorProps {
  allVendors: Array<any>;
}

const ViewBrandVendor = (props: ViewBrandVendorProps) => {
  const { allVendors } = props;

  const navigate = useNavigate();

  const vendorDetails = (id: number) => {
    console.log(id);
    navigate(ROUTES.VENDOR_DETAILS, { state: { id } });
  };

  return (
    <div className="flex flex-column gap-3">
      {allVendors.map((item) => {
        return (
          <div
            className="flex flex-column p-3 px-4 cursor-pointer rounded-4 border bg-light"
            onClick={() => {
              vendorDetails(item.id);
            }}
            key={item.id}
          >
            <div className="flex justify-content-between">
              <div className="flex text-sm gap-4 w-75">
                {item.logoUrl !== "null" && (
                  <Image src={item.logoUrl} height={78} width={78}></Image>
                )}
                <table className="table table-borderless text-sm table-responsive transparent-table">
                  <thead>
                    <tr className="text-sm">
                      <td
                        className="w-25"
                        style={{ color: "#8B8C89", fontWeight: "400" }}
                      >
                        Brand Name
                      </td>
                      <td style={{ color: "#8B8C89", fontWeight: "400" }}>
                        Contacts
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="fs-6">
                      <td className="fw-semibold">{item.name}</td>
                      <td>
                        <div
                          className="flex align-items-center gap-2"
                          style={{ fontSize: "15px" }}
                        >
                          <div className="border rounded-3 p-1 px-2">
                            {item.email}
                          </div>
                          {/* <div className="border rounded-3 p-1 px-2">
                            +91 9823198910
                          </div> */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="table table-borderless text-center transparent-table">
                  <thead>
                    <tr className="text-sm">
                      <td className="fw-normal text-secondary">Payables</td>
                      <td className="fw-normal text-secondary">
                        Total Products
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="fw-semibold text-3xl">
                      <td style={{ color: "#000000", fontWeight: "600" }}>
                        ₹ 2000
                      </td>
                      <td style={{ color: "#000000", fontWeight: "600" }}>
                        {item.products.length}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-content-between align-items-center rounded-2 p-2 px-3 border bg-white bg-opacity-75">
              <div className="ml-5 text-sm text-secondary">{item.address}</div>
              <div className="flex gap-3 text-sm align-items-center">
                <span className="text-secondary">PORTAL STATUS</span>
                <Button
                  variant=""
                  className="px-3 text-sm text-white rounded-3 bg-info"
                >
                  {item.portalStatus ? "Active" : "Not Active"}
                </Button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ViewBrandVendor;
