// @ts-nocheck

// Categories
import Basket from "./images/categories/Basket.svg";
import Boxes from "./images/categories/Boxes.svg";

// Brands
import CheckList from "./images/brands/CheckList.svg";
import SingleProduct from "./images/brands/SingleProduct.svg";

// Products
import ReadyProduct from "./images/Products/ready-stock 1.svg";
import OutOfStock from "./images/Products/boxes.svg";
import Stocks from "./images/Products/package1.svg";
import Edit from "./images/Products/edit.svg";
import Delete from "./images/Products/delete.svg";

// Inventory
import cancel from "./images/Invetorytable/x-mark 1.png";
import more from "./images/Invetorytable/more 1.png";

// Vendor
import contact from "./images/vendors/contact.png";
import money from "./images/vendors/money-currency.png";
import pending from "./images/vendors/pending.png";

// Transaction
import delivered from "./images/Transaction/delivered.svg";
import fastDelivery from "./images/Transaction/fast-delivery.svg";
import packageDelivered from "./images/Transaction/package-delivered.svg";

// Vendors
import Truck from "./images/vendors/Truck.png";
import Ballon from "./images/vendors/Ballon.svg";

// General
import Success from "./images/General/Success.svg";

// PurchaseOrder
import CardMan from "./images/PurchaseOrders/CardMan.png";

// Customer
import Oldest from "./images/Customer/oldest.svg";
import Recent from "./images/Customer/recent.svg";
import QRcode from "./images/Customer/qr-code.svg";

// Login
import Owner from "./images/login/owner.svg";
import Store from "./images/login/store.svg";
import Invent from "./images/login/invent.svg";
import Customer from "./images/login/customer.svg";

// Temp
import LogoImage from "./images/temp/logo-image.svg";
import demoImg from "./images/temp/demoimg.svg";

// Below images are only use for test purpose
import Rice from "./images/temp/Brownrice 1.png";
import Brand from "./images/temp/brand.png";

export const images = {
  Basket,
  Boxes,
  CheckList,
  SingleProduct,
  ReadyProduct,
  OutOfStock,
  Stocks,
  Edit,
  Delete,
  cancel,
  more,
  contact,
  money,
  pending,
  Rice,
  Brand,
  delivered,
  fastDelivery,
  packageDelivered,
  demoImg,
  Truck,
  Ballon,
  Success,
  CardMan,
  LogoImage,
  Oldest,
  Recent,
  QRcode,
  Owner,
  Store,
  Invent,
  Customer,
};
