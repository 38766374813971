import { Image } from "primereact/image";
import { images } from "../../assets";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../navigators/Routes";

type Props = {};

const Logo = (props: Props) => {
	const navigate = useNavigate();

	return (
		<div className="sidebar-header gap-1" onClick={() => navigate(ROUTES.MAIN)}>
			<Image
				src={images.LogoImage}
				height="35"
				style={{ objectFit: "cover" }}
			/>
			<div>
				<p style={{ fontSize: "14px", fontWeight: "900", margin: 0 }}>
					urbanlyfe
				</p>
				<p
					style={{
						fontSize: "14px",
						fontWeight: "900",
						margin: 0,
						lineHeight: "12px",
					}}
				>
					for retailers
				</p>
			</div>
		</div>
	);
};

export default Logo;
