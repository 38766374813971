import { useEffect, useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { Button, Modal } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

import { ROUTES } from "../../navigators/Routes";

import { images } from "../../assets";

import VerticalInput from "../../components/VerticalInput/VerticalInput";
import { ProductTable } from "../../components/Vendo&ProductDetailsTable/ProductTable";
import PaymentTerms from "../../components/PaymentTerms/PaymentTerms";
import DeletePopUp from "../../components/DeletePopUp/DeletePopUp";
import { getUserData } from "../../utils/helper";

const SendMail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const mailData = location.state;

  const [subject, setSubject] = useState("");
  const [form, setForm] = useState("");
  const [to, setTo] = useState("");
  const [orderDate, setOrderDate] = useState<Date>();
  const [eDeliveryDate, setEDeliveryDate] = useState<Date>();
  const [paymentTerms, setPaymentTerms] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [gst, setGst] = useState("");
  const [discount, setDiscount] = useState("");
  const [discountAmountType, setDiscountAmountType] = useState("");
  const [adjustment, setAdjustment] = useState("");

  const [showDelete, setShowDelete] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    const getPreRequiredData = async () => {
      const user = await getUserData();
      const subject = `Purchase Order from Urbanlyfe (${mailData.poNo})`;
      setSubject(subject);
      setForm(user.email);
      setTo(mailData.toEmail);
      setOrderDate(mailData.orderDate.toISOString());
      setEDeliveryDate(mailData.eDeliveryDate.toISOString());
      setPaymentTerms(mailData.paymentTerms);
      setPaymentMode(mailData.paymentMode);
      setGst(mailData.gst);
      setDiscount(mailData.discount);
      setDiscountAmountType(mailData.discountAmountType);
      setAdjustment(mailData.adjustment);
    };
    getPreRequiredData();
  }, [mailData]);

  const handleClose = () => {
    setShowDelete(false);
    setShowSuccess(false);
  };

  const sendMail = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
      navigate(ROUTES.ALL_PURCHASE_ORDERS);
    }, 3000);
  };

  return (
    <div className="p-3 flex flex-column gap-3">
      <h4 className="fw-bold">New Purchase Order</h4>

      {/* SEND MAIL Details */}
      <div className="p-3 bg-light rounded-4">
        <h2 className="fw-semibold text-xl mb-4">Send Email</h2>
        <div className="col-md-6 col-lg-3 w-100">
          <VerticalInput
            label="Subject"
            value={subject}
            readOnly={true}
            placeHolder=""
          />
        </div>
        <div className="flex justify-content-between gap-3 mt-3">
          <div className="flex gap-3 w-9">
            <div className="w-100">
              <VerticalInput
                label="From"
                value={form}
                readOnly={true}
                placeHolder=""
              />
            </div>
            <div className="w-100">
              <VerticalInput
                label="To"
                value={to}
                readOnly={true}
                placeHolder=""
              />
            </div>
          </div>
          <div className="flex gap-3 text-xs text-secondary">
            {" "}
            <span>CC</span> <span>BCC</span>
          </div>
        </div>
      </div>

      {/* Mail Body */}
      <div className="p-3 flex flex-column gap-5 mb-8">
        {/* Body Text */}
        <div className="flex flex-column gap-4 text-sm fw-bold">
          <span>Dear ABC Store,</span>
          <span>
            The purchase order {`(${mailData.poNo})`} is attached with this
            email.
          </span>
          <span>An overview of the purchase order is available below: </span>
          <div className="w-4">
            <div className="flex gap-3">
              <div className="w-5">Order Date</div>
              <div className="flex justify-content-between w-4">
                <span>:</span>
                <span>
                  {orderDate && new Date(orderDate).toLocaleDateString()}
                </span>
              </div>
            </div>
            <div className="flex gap-3">
              <div className="w-5">Expected Delivery Date</div>
              <div className="flex justify-content-between w-4">
                <span>:</span>
                <span>
                  {eDeliveryDate &&
                    new Date(eDeliveryDate).toLocaleDateString()}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Product Info */}
        <div>
          <h2 className="fw-semibold text-xl mb-4">Product Information</h2>
          <ProductTable />
        </div>

        {/* Payment Terms */}
        <PaymentTerms
          paymentMode={paymentMode}
          paymentTerm={paymentTerms}
          gst={gst}
          discount={discount}
          discountAmountType={discountAmountType}
          adjustment={adjustment}
        />

        {/* Attach PDF */}
        <div className="flex justify-content-between px-3 text-sm rounded-3 p-2 bg-eee">
          <div className="flex align-items-center w-5 gap-3">
            <input
              type="checkbox"
              style={{ width: "4%", height: "400%" }}
              checked
            />
            <span>Attach Purchase Order PDF</span>
          </div>
          <div
            className="px-2 p-1 flex align-items-center text-sm gap-2 w-2"
            style={{
              color: "#5A5A5A",
              backgroundColor: "#FCFCFC",
              border: "1px dashed #CFCFCF",
            }}
          >
            <FontAwesomeIcon icon={faFilePdf} size="xl" />
            {mailData.poNo}
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="p-2 b-bar w-10 px-3 bg-white rounded-3 shadow flex justify-content-between align-items-center text-base">
        <div className="flex gap-3 fw-bold">
          <div className="text-secondary">Total</div>
          <div>₹1,395.00</div>
        </div>
        <div className="flex gap-4">
          <Button
            variant=""
            className="rounded-3 cancel px-4 text-sm"
            onClick={() => {
              setShowDelete(true);
            }}
          >
            Cancel
          </Button>
          <Button
            variant=""
            className="rounded-3 hover:bg-primary text-sm bg-dark text-white"
            onClick={sendMail}
          >
            Send Mail <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
          </Button>
        </div>
      </div>

      {/* Delete PopUP */}
      <DeletePopUp
        btnName={"Save as Draft"}
        show={showDelete}
        body={
          "Any unsaved changes will be lost. Do you want to save them in drafts?"
        }
        handleClose={handleClose}
      />

      {/* Success Dialog Box */}
      <Modal
        show={showSuccess}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        centered
      >
        <Modal.Body className="fw-bold text-xl flex justify-content-center px-7">
          <div className="flex flex-column align-items-center justify-content-between gap-4 p-3">
            <div className="flex flex-column gap-3 justify-content-center align-items-center">
              <img src={images.Success} alt="Success" width={75} height={75} />
              <div className="text-3xl text-success fw-bold text-center">
                Success
              </div>
            </div>
            <h2 className="fw-semibold text-xl">
              Purchase Order Sent Successfully!
            </h2>
            <p className="fw-normal text-center text-lg">
              Your order details have been emailed to the vendor. You will
              receive a confirmation soon.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SendMail;
