import {
	faBox,
	faCartFlatbed,
	faCartPlus,
	faFlag,
	faList,
	faHouse,
	faChartLine,
	faMagnifyingGlassChart,
} from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "../navigators/Routes";

export const STORAGE_KEYS = {
	IS_USER_AUTHENTICATED: "isUserAuthenticated",
	USER_DATA: "userData",
};

export const PAGES = {
	LOGIN: "login",
};

export const SIDE_MENU_ITEMS = [
	{
		id: "1",
		title: "Dashboard",
		icon: faHouse,
		path: ROUTES.MAIN,
	},
	{
		id: "2",
		title: "Inventory",
		icon: faCartFlatbed,
		path: "",
		subMenu: [
			{
				id: "1",
				title: "Brands",
				icon: faFlag,
				path: ROUTES.BRANDS,
				subMenu: [],
			},
			{
				id: "2",
				title: "Categories",
				icon: faList,
				path: ROUTES.CATEGORIES,
				subMenu: [],
			},
			{
				id: "3",
				title: "Products",
				icon: faBox,
				path: ROUTES.PRODUCTS,
				subMenu: [],
			},
			{
				id: "4",
				title: "Adjustments",
				icon: faBox,
				path: ROUTES.ADJUSTMENTS,
				subMenu: [],
			},
		],
	},
	{
		id: "3",
		title: "Purchases",
		icon: faCartPlus,
		path: "",
		subMenu: [
			{
				id: "1",
				title: "Brands(Vendors)",
				icon: faList,
				path: ROUTES.VENDOR,
				subMenu: [],
			},
			{
				id: "2",
				title: "Purchase Orders",
				icon: faList,
				path: ROUTES.PURCHASE_ORDERS,
				subMenu: [],
			},
			{
				id: "3",
				title: "Bills",
				icon: faList,
				path: ROUTES.BILLS,
				subMenu: [],
			},
			// {
			// 	id: "4",
			// 	title: "Profile",
			// 	icon: faList,
			// 	path: ROUTES.PROFILE,
			// 	subMenu: [],
			// },
		],
	},
	{
		id: "4",
		title: "Sales",
		icon: faChartLine,
		path: "",
		subMenu: [
			{
				id: "1",
				title: "Customers",
				icon: faList,
				path: ROUTES.CUSTOMERS,
				subMenu: [],
			},
			{
				id: "2",
				title: "Orders",
				icon: faList,
				path: ROUTES.ORDERS,
				subMenu: [],
			},
		],
	},
	{
		id: "5",
		title: "Discover Brands",
		icon: faMagnifyingGlassChart,
		path: ROUTES.DISCOVER_BRANDS,
	},
];

export const WEIGHT_UNITS = [
	{
		id: "1",
		name: "kg",
	},
	{
		id: "2",
		name: "g",
	},
	{
		id: "3",
		name: "lb",
	},
	{
		id: "4",
		name: "oz",
	},
];
