interface paymentProps {
  paymentTerm: string;
  paymentMode: string;
  gst: string;
  discount: string;
  discountAmountType: string;
  adjustment: string;
}
const PaymentTerms = (props: paymentProps) => {
  const {
    paymentTerm,
    paymentMode,
    gst,
    discount,
    discountAmountType,
    adjustment,
  } = props;

  return (
    <div className="flex justify-content-between">
      <div className="w-50 flex flex-column gap-4">
        <h2 className="fw-semibold text-xl">Payment</h2>
        <div className="flex gap-5">
          <div className="row gap-2 w-5">
            <div className="text-sm text-secondary fw-bold">Payment Terms</div>
            <input
              type="text"
              value={paymentTerm}
              className="form-control"
              readOnly
            />
          </div>
          <div className="row gap-2 w-5">
            <div className="text-sm text-secondary fw-bold">Payment Mode</div>
            <input
              type="text"
              value={paymentMode}
              className="form-control"
              readOnly
            />
          </div>
        </div>
        <div className="text-sm text-secondary">
          <span className="fw-bold mr-2">Note:</span>All products ordered must
          meet the specified specifications and quality requirements as detailed
          in purchase order.
        </div>
      </div>
      <div className="d-flex flex-column gap-3 text-sm p-3 px-4 bg-light rounded-3 w-50">
        <div className="d-flex justify-content-between">
          <span className="detail-text fw-bold">
            Sub Total <span className="text-xs fw-normal">(inclusive GST)</span>
          </span>
          <span className="detail-amount">₹1,500.00</span>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <span className="detail-text fw-bold">
            GST <span className="text-xs fw-normal">(Goods & Service Tax)</span>
          </span>
          <div className="d-flex justify-content-between align-items-center w-6">
            <div className="w-7 flex align-items-center">
              <input
                type="number"
                value={gst}
                readOnly
                className="p-2 rounded-start w-100 border outline-none text-center"
              />
              <span className="border p-2 px-3 rounded-end">%</span>
            </div>
            <span className="ms-2 detail-amount">₹75.00</span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <span className="detail-text fw-bold">Discount</span>
          <div className="d-flex justify-content-between align-items-center w-6">
            <div className="w-7 flex align-items-center">
              <input
                type="number"
                value={discount}
                readOnly
                className="p-2 rounded w-100 border outline-none text-center"
              />
              <div className="py-2">{discountAmountType}</div>
            </div>
            <span className="ms-2 detail-amount">(₹30.00)</span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <span className="detail-text fw-bold">Adjustments</span>
          <div className="d-flex justify-content-between align-items-center w-6">
            <div className="w-7 flex align-items-center">
              <input
                type="number"
                value={adjustment}
                readOnly
                className="p-2 rounded-start w-100 border outline-none text-center"
              />
              <span className="border p-2 px-3 rounded-end">₹</span>
            </div>
            <span className="ms-2 detail-amount">(₹75.00)</span>
          </div>
        </div>
        <div className="d-flex fw-bold justify-content-between text-base border-top pt-3">
          <span>Total Amount</span>
          <span>₹1,395.00</span>
        </div>
      </div>
    </div>
  );
};

export default PaymentTerms;
